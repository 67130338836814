export namespace component {
  export async function getAllComponents(token: string, host: any) {
    return fetch(host + "/api/components/getAll?token=" + token);
  }
  export async function getComponentWeek(token: string, host: any) {
    return fetch(host + "/api/components/getWeeks?token=" + token);
  }
  export async function getRecycledComponents(token: string, host: any) {
    return fetch(host + "/api/components/getRecycled?token=" + token);
  }
  export async function getAllGroups(token: string, host: any) {
    return fetch(host + "/api/components/getAll?token=" + token);
  }
  export async function recycleComponent(
    token: string,
    id: number,
    host: any
  ): Promise<any> {
    return await fetch(
      host + "/api/components/recycle?token=" + token + "&id=" + id
    );
  }
  export async function cleanComponents(
    token: string,
    host: any
  ): Promise<any> {
    return await fetch(host + "/api/groups/clean?token=" + token);
  }
  export async function addComponent(
    token: string,
    componentItem: any,
    host: any
  ) {
    let result: Response;
    componentItem.token = token;
    result = (await fetch(host + "/api/components/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&group1_id=" +
        componentItem.group1_id +
        "&group2_id=" +
        componentItem.group2_id +
        "&group3_id=" +
        componentItem.group3_id +
        "&group4_id=" +
        componentItem.group4_id +
        "&group5_id=" +
        componentItem.group5_id +
        "&literal=" +
        componentItem.literal +
        "&recycled=false"
    })) as any;

    return await result.json();
  }
  export async function editComponent(
    token: string,
    componentItem: any,
    host: any
  ) {
    let result: Response;
    componentItem.token = token;
    result = (await fetch(host + "/api/components/Add", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body:
        "token=" +
        token +
        "&id=" +
        componentItem.id +
        "&group1_id=" +
        componentItem.group1_id +
        "&group2_id=" +
        componentItem.group2_id +
        "&group3_id=" +
        componentItem.group3_id +
        "&group4_id=" +
        componentItem.group4_id +
        "&group5_id=" +
        componentItem.group5_id +
        "&literal=" +
        componentItem.literal +
        "&recycled=false"
    })) as any;

    return await result.json();
  }
}
