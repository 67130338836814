<template>
  <div>
    <el-row class="container">
      <el-input
        v-model="searchQuery"
        placeholder="Search..."
        clearable
        style="margin-bottom: 20px;"
      >
      </el-input>
      <el-table
        :data="filteredVacations"
        :row-class-name="vacationStatus1"
        :default-sort="{ prop: 'decision', order: 'ascending' }"
        :header-cell-style="{
          background: 'rgb(170, 238, 238)',
          color: 'black',
          'text-align': 'center',
        }"
        border
      >
        <el-table-column
          label="Name"
          sortable
          :formatter="getName"
          width="200px"
          align="center"
          :sort-method="(a, b) => sortName(a, b)"
        ></el-table-column>
        <el-table-column
          prop="total_days_req"
          sortable
          label="Total days requested"
          width="90px"
          align="center"
          :sort-method="(a, b) => sorttotalDateRequestedDate(a, b)"
        ></el-table-column>
        <el-table-column
          sortable
          :formatter="getleaveon"
          label="Leave on"
          width="150px"
          align="center"
          :sort-method="(a, b) => sortDate(a, b)"
        ></el-table-column>
        <el-table-column
          sortable
          :formatter="getleaveend"
          label="Leave end"
          width="150px"
          align="center"
          :sort-method="(a, b) => sortDatesortDateLeaveEnd(a, b)"
        ></el-table-column>
        <el-table-column
          sortable
          :formatter="getTypeOfvacation"
          label="Type"
          width="130px"
          align="center"
          :sort-method="(a, b) => sortTypeOfvacation(a, b)"
        ></el-table-column>
        <el-table-column
          sortable
          label="Status"
          :formatter="getStatus"
          width="110px"
          align="center"
          :sort-method="(a, b) => sortStatus(a, b)"
        ></el-table-column>
        <el-table-column label="Actions" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="userlevel < 2"
              type="primary"
              @click="Edit(scope.$index, scope.row)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              v-if="userlevel < 2"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="open(scope.$index, scope.row)"
            ></el-button>
            <el-button
              v-if="userlevel < 2"
              type="warning"
              icon="el-icon-printer"
              circle
              @click="exportVacation(scope.$index, scope.row)"
            ></el-button>
            <el-button
              v-if="
                userlevel >= 2 &&
                  scope.row.decision !== 2 &&
                  scope.row.decision !== 3
              "
              type="success"
              @click="addApproved(scope.$index, scope.row)"
              icon="el-icon-check"
              circle
            ></el-button>
            <el-button
              v-if="
                userlevel >= 2 &&
                  scope.row.decision !== 2 &&
                  scope.row.decision !== 3
              "
              type="danger"
              @click="addRejected(scope.$index, scope.row)"
              icon="el-icon-close"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { personnel } from "../../api/personnel/personnel";
import { vacation } from "../../api/vacation";
import Moment from "moment";
import Component from "vue-class-component";
import { postgrest } from "../../api/postgrest";

@Component({
  name: "ViewVacationSubmitted",
})
export default class ViewVacationSubmitted extends Vue {
  public viewVacationSubmitted: any[] = [];
  public viewVacationSubmitted1: any[] = [];
  public personnelList: any[] = [];
  public TypeofvacationList: any[] = [];
  public loading: boolean = true;
  public userlevel: number = -1;
  public statusofvacation: number = 1;
  public statusofvacationList: any[] = [];
  public id1: number = -1;
  public year1: any = "";
  public nameAsc: boolean = false;
  public dateAsc: boolean = false;
  public searchQuery: string = "";
  scope: any;

  public async created() {
    this.loading = true;
    let object = this.$store.getters.getCurrentObject;

    this.year1 = object.year;

    if (object.id != undefined) this.id1 = object.id.id;

    this.$store.dispatch("updateCurrentObject", {
      year: undefined,
      id: undefined,
    });

    await this.getPersonnelList();
    await this.getvacationList();
    await this.getTypeofvacationList();
    await this.getStatusofvacationList();
  }

  public exportVacation(rowIndex: number, row: any) {
    let temp = { decisionofvacation: 1 };
    const row1 = Object.assign(temp, row);
    this.$store.dispatch("updateCurrentObject", row1);
    this.$store.dispatch("updateCurrentComponent", "DownloadVacation");
    this.$router.replace({
      name: "load",
    });
  }

  public vacationStatus1({
    row,
    rowIndex,
  }: {
    row: number;
    rowIndex: number;
  }): String {
    return this.getStatus(row, 1);
  }

  public getleaveon(row: any) {
    let x = JSON.parse(row.json_leaveon_leaveend);
    let temp = "";
    for (let c in x) {
      temp += Moment(x[c].leave_on).format("DD/MM/YYYY") + "\n";
    }
    return temp;
  }

  public getleaveend(row: any) {
    let x = JSON.parse(row.json_leaveon_leaveend);
    let temp = "";
    for (let c in x) {
      temp += Moment(x[c].leave_end).format("DD/MM/YYYY") + "\n";
    }
    return temp;
  }

  public getreturnon(row: any) {
    if (row.return_on === undefined) {
      return "";
    }
    return Moment(row.return_on).format("DD/MM/YYYY");
  }

  public async getvacationList() {
    let response = await postgrest.get(
      "vacation",
      this.$store.getters.getToken
    );
    this.viewVacationSubmitted1 = response;
    this.viewVacationSubmitted = [];
    for (const a of this.viewVacationSubmitted1) {
      var x = false;
      for (const b of this.personnelList) {
        if (a.idpersonel == b.id && b.firstname != "x") {
          x = true;
          break;
        }
      }
      if (x == true) {
        var temp = { seen: true };
        if (a.decision == undefined) {
          a.decision = 1;
        }
        if (a.decision == 1) {
          temp = { seen: false };
        }
        var temp2 = a;
        this.viewVacationSubmitted.push(Object.assign(temp2, temp));
        this.viewVacationSubmitted.sort(function(a, b) {
          var aSeen = a.seen;
          var bSeen = b.seen;
          var aDate = new Date(a.date_posted);
          var bDate = new Date(b.date_posted);

          if (aSeen == bSeen) {
            return aDate.getTime() < bDate.getTime()
              ? -1
              : aDate.getTime() > bDate.getTime()
              ? 1
              : 0;
          } else {
            return aSeen < bSeen ? -1 : 1;
          }
        });
      }
    }
    if (this.id1 != undefined && this.id1 != -1) {
      this.viewVacationSubmitted = this.viewVacationSubmitted.filter(
        (word) =>
          word.year_vacation == this.year1 && word.idpersonel == this.id1
      );
    }
    this.loading = false;
  }

  public async getPersonnelList() {
    this.personnelList = await postgrest.get(
      "personnel?select=*,companies(*),wo_specialties(*),specialties(*)",
      this.$store.getters.getToken
    );
  }

  public async addApproved(rowIndex: number, row: any) {
    await vacation.approvedVacation(
      this.$store.getters.getToken,
      row.id,
      this.$store.getters.getHost
    );
    this.getvacationList();
  }

  public async addRejected(rowIndex: number, row: any) {
    await vacation.rejectedVacation(
      this.$store.getters.getToken,
      row.id,
      this.$store.getters.getHost
    );
    this.getvacationList();
  }

  public async deleteVA(rowIndex: number, row: any) {
    await vacation.deleteVacation(
      this.$store.getters.getToken,
      row.id,
      this.$store.getters.getHost
    );
    this.getvacationList();
  }

  public async open(rowIndex: number, row: any) {
    this.$confirm("This will permanently delete. Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        this.$message({
          type: "success",
          message: "Delete completed",
        });
        this.deleteVA(rowIndex, row);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete canceled",
        });
      });
  }

  public async getTypeofvacationList() {
    await vacation
      .getAllType(this.$store.getters.getToken, this.$store.getters.getHost)
      .then((response) => {
        response.json().then((resp) => {
          this.TypeofvacationList = JSON.parse(JSON.stringify(resp));
        });
      });
  }

  public async getStatusofvacationList() {
    await vacation
      .getAllStatusofvacation(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then((response) => {
        response.json().then((resp) => {
          this.statusofvacationList = JSON.parse(JSON.stringify(resp));
        });
      });
  }

  public sortName(a: any, b: any) {
    this.nameAsc = !this.nameAsc;
    if (this.nameAsc) {
      return this.getName(a, null) > this.getName(b, null) ? 1 : -1;
    }
    return this.getName(a, null) > this.getName(b, null) ? 1 : -1;
  }

  public sortStatus(a: any, b: any) {
    return this.getStatus(a, null) > this.getStatus(b, null) ? 1 : -1;
  }

  public sortTypeOfvacation(a: any, b: any) {
    this.nameAsc = !this.nameAsc;
    if (this.nameAsc) {
      return this.getTypeOfvacation(a, null) > this.getTypeOfvacation(b, null)
        ? 1
        : -1;
    }
    return this.getTypeOfvacation(a, null) > this.getTypeOfvacation(b, null)
      ? 1
      : -1;
  }

  public sorttotalDateRequestedDate(a: any, b: any) {
    return a.total_days_req > b.total_days_req ? 1 : -1;
  }

  public sortDate(a: any, b: any) {
    return Moment(a.leave_on).format("YYYY/MM/DD") >
      Moment(b.leave_on).format("YYYY/MM/DD")
      ? 1
      : -1;
  }

  public sortDatesortDateLeaveEnd(a: any, b: any) {
    return Moment(a.leave_end).format("YYYY/MM/DD") >
      Moment(b.leave_end).format("YYYY/MM/DD")
      ? 1
      : -1;
  }

  public sortDatesortDateReturnOn(a: any, b: any) {
    return Moment(a.return_on).format("YYYY/MM/DD") >
      Moment(b.return_on).format("YYYY/MM/DD")
      ? 1
      : -1;
  }

  public getName(row: any, column: any): String {
    let temp = row.idpersonel;
    let tem2 = this.personnelList.find(function(element) {
      return temp == element.id;
    });

    if (tem2.firstname != "x") {
      return tem2.firstname + " " + tem2.lastname;
    } else {
      return "x";
    }
  }

  public getStatus(row: any, column: any): String {
    let temp5 = row.decision;
    let tem6 = this.statusofvacationList.find(function(element) {
      return temp5 == element.id;
    });
    if (tem6 === undefined) return "Pending";
    return tem6.literal;
  }

  public getTypeOfvacation(row: any, column: any): String {
    let temp3 = row.type_of_vacation;
    let temp4 = this.TypeofvacationList.find(function(element) {
      return temp3 == element.id;
    });

    if (temp4 === undefined) return "na";
    return temp4.type_of_vacation;
  }

  public Edit(rowIndex: number, row: any) {
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddNewVacation");
    this.$router.replace({ name: "load" });
  }

  get filteredVacations() {
    return this.viewVacationSubmitted.filter((vacation) => {
      const searchTerm = this.searchQuery.toLowerCase();
      return (
        this.getName(vacation, null)
          .toLowerCase()
          .includes(searchTerm) ||
        vacation.total_days_req.toString().includes(searchTerm) ||
        this.getleaveon(vacation)
          .toLowerCase()
          .includes(searchTerm) ||
        this.getleaveend(vacation)
          .toLowerCase()
          .includes(searchTerm) ||
        this.getTypeOfvacation(vacation, null)
          .toLowerCase()
          .includes(searchTerm) ||
        this.getStatus(vacation, null)
          .toLowerCase()
          .includes(searchTerm)
      );
    });
  }
}
</script>

<style>
.el-table .Pending {
  background: rgb(236, 255, 149);
}

.el-table .Completed {
  background: #ffffff;
}

.el-table .Cancelled {
  background: rgb(255, 186, 186);
}

.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}

.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}

.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}

u {
  text-decoration: underline;
}

.hours {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(230, 242, 255);
  padding-right: 10px;
}
</style>
