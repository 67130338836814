<template>
  <div>
    <div class="container">
      <h2>Filters</h2>
      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>From:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-date-picker
            v-model="filterFrom"
            type="date"
            placeholder="Select Date"
            size="mini"
            clearable
            class="date-select"
          ></el-date-picker>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>To:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-date-picker
            v-model="filterTo"
            type="date"
            placeholder="Select Date"
            size="mini"
            clearable
            class="date-select"
          ></el-date-picker>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Hall:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedHall"
            placeholder="Select Hall"
            class="hall-select"
            clearable
            size="mini"
          >
            <el-option
              v-for="hall in halls"
              :key="hall"
              :label="hall"
              :value="hall"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Status:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedStatus"
            placeholder="Select Status"
            class="status-select"
            clearable
            size="mini"
          >
            <el-option
              v-for="status in statuses"
              :key="status"
              :label="status"
              :value="status"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Periodicity:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedPeriodicity"
            placeholder="Select Periodicity"
            class="periodicity-select"
            clearable
            size="mini"
          >
            <el-option
              v-for="period in periodicities"
              :key="period"
              :label="period"
              :value="period"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>System:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedSystem"
            placeholder="Select System"
            class="system-select"
            clearable
            size="mini"
            filterable
          >
            <el-option
              v-for="system in systems"
              :key="system.id"
              :label="system.system"
              :value="system.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Subsystem:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedSubsystem"
            placeholder="Select Subsystem"
            class="subsystem-select"
            clearable
            filterable
            size="mini"
          >
            <el-option
              v-for="subsystem in subsystems"
              :key="subsystem.id"
              :label="subsystem.subsystem"
              :value="subsystem.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Foreman:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedForeman"
            placeholder="Select Foreman"
            class="foreman-select"
            clearable
            filterable
            size="mini"
          >
            <el-option
              v-for="foreman in foremen"
              :key="foreman.id"
              :label="`${foreman.firstname} ${foreman.lastname}`"
              :value="foreman.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Sen. Tech:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedSenTech"
            placeholder="Select Senior Tech"
            class="sen-tech-select"
            clearable
            filterable
            size="mini"
          >
            <el-option
              v-for="senTech in senTechs"
              :key="senTech.id"
              :label="`${senTech.firstname} ${senTech.lastname}`"
              :value="senTech.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Action:</strong>
          </div>
        </el-col>
        <el-col :span="18" align="left">
          <el-select
            v-model="selectedAction"
            placeholder="Select Action"
            class="action-select"
            clearable
            size="mini"
          >
            <el-option
              v-for="action in actions"
              :key="action"
              :label="action"
              :value="action"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <br />
      <el-button type="success" @click="filterData">Search</el-button>
    </div>

    <div class="container">
      <h2>PPM List</h2>
      <el-input
        v-model="tableSearchQuery"
        placeholder="Search..."
        class="table-search-input"
        clearable
      ></el-input>
      <el-table
        :data="filteredTablePPMList"
        stripe
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          prop="periodicity"
          label="Periodicity"
        ></el-table-column>
        <el-table-column prop="hall" label="Hall"></el-table-column>
        <el-table-column prop="systemName" label="System"></el-table-column>
        <el-table-column
          prop="subsystemName"
          label="Subsystem / Name"
        ></el-table-column>
        <el-table-column prop="status" label="Status"></el-table-column>
        <el-table-column prop="date" label="Date" sortable></el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="viewDetails(scope.row)"
              icon="el-icon-view"
              circle
            ></el-button>
            <el-button
              type="warning"
              size="mini"
              @click="editPPM(scope.row)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              @click="deletePPM(scope.row)"
              icon="el-icon-delete"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <h2>PPM Details</h2>
      <div class="dialog-content" v-if="selectedPPM">
        <el-table :data="[selectedPPM]" border>
          <el-table-column prop="systemName" label="System"></el-table-column>
          <el-table-column prop="hall" label="Hall"></el-table-column>
          <el-table-column prop="date" label="Date"></el-table-column>
          <el-table-column prop="foremanName" label="Foreman"></el-table-column>
          <el-table-column
            prop="senTechName"
            label="Senior Tech"
          ></el-table-column>
          <el-table-column prop="remarks" label="Remarks" width="300">
            <template slot-scope="scope">
              <div
                v-for="(remark, index) in scope.row.remarks"
                :key="remark.workOrderId"
              >
                <strong>{{ index + 1 }}. Remark:</strong> {{ remark.text
                }}<br />
                <strong>Action:</strong> {{ remark.action }}<br />
                <hr v-if="index < scope.row.remarks.length - 1" />
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="status" label="Status"></el-table-column>
          <el-table-column
            prop="completion_date"
            label="Completion Date"
          ></el-table-column>
          <el-table-column
            prop="periodicity"
            label="Periodicity"
          ></el-table-column>
        </el-table>
        <div style="margin-top: 20px;">
          <h2>Work Orders:</h2>
          <el-table :data="selectedPPM.remarks" border>
            <el-table-column prop="workOrderId" label="ID"></el-table-column>
            <el-table-column
              prop="description"
              label="Description"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="editDialogVisible"
      width="70%"
      :before-close="handleEditClose"
      class="edit-dialog"
    >
      <h2>Edit PPM Details</h2>
      <div class="dialog-content" v-if="editablePPM">
        <el-form :model="editablePPM" label-width="120px" class="edit-form">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Hall">
                <el-select
                  v-model="editablePPM.hall"
                  placeholder="Select Hall"
                >
                  <el-option
                    v-for="hall in halls"
                    :key="hall"
                    :label="hall"
                    :value="hall"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="System">
                <el-select
                  v-model="editablePPM.system"
                  placeholder="Select System"
                >
                  <el-option
                    v-for="system in filteredSystems"
                    :key="system.id"
                    :label="system.system"
                    :value="system.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Subsystem">
                <el-select
                  v-model="editablePPM.subsystem"
                  placeholder="Select Subsystem"
                >
                  <el-option
                    v-for="subsystem in subsystems"
                    :key="subsystem.id"
                    :label="subsystem.subsystem"
                    :value="subsystem.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Status">
                <el-select
                  v-model="editablePPM.status"
                  placeholder="Select Status"
                  @change="handleStatusChange"
                >
                  <el-option
                    v-for="status in statuses"
                    :key="status"
                    :label="status"
                    :value="status"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="Completion Date"
                v-if="editablePPM.status === 'Completed'"
              >
                <el-date-picker
                  v-model="editablePPM.completion_date"
                  type="date"
                  placeholder="Select Completion Date"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <!-- Edit techs -->
              <el-form-item label="Techs">
                <div
                  v-for="(tech, index) in editablePPM.techs"
                  :key="tech.id"
                  class="tech-entry"
                >
                  <el-select
                    v-model="tech.label"
                    placeholder="Select Tech"
                    size="mini"
                    class="tech-select"
                    filterable
                  >
                    <el-option
                      v-for="foreman in foremen"
                      :key="foreman.id"
                      :label="`${foreman.firstname} ${foreman.lastname}`"
                      :value="`${foreman.firstname} ${foreman.lastname}`"
                    ></el-option>
                  </el-select>
                  <el-date-picker
                    v-model="tech.date"
                    type="date"
                    placeholder="Date"
                    size="mini"
                    class="tech-date-picker"
                  ></el-date-picker>
                  <el-input
                    v-model="tech.start"
                    placeholder="Start Time (HH:MM)"
                    size="mini"
                    class="tech-time-input"
                  ></el-input>
                  <el-input
                    v-model="tech.end"
                    placeholder="End Time (HH:MM)"
                    size="mini"
                    class="tech-time-input"
                  ></el-input>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="removeTech(index)"
                    circle
                    size="mini"
                  ></el-button>
                </div>
                <el-button
                  type="success"
                  icon="el-icon-plus"
                  @click="addTech"
                  size="mini"
                  class="add-tech-button"
                >
                  Add Tech
                </el-button>
              </el-form-item>
              <!-- Edit remarks -->
              <el-form-item label="Remarks">
                <div
                  v-for="(remark, index) in editablePPM.remarks"
                  :key="index"
                  class="remark-entry"
                >
                  <el-col :span="8" align="left">
                    <el-select
                      v-model="remark.workOrderId"
                      filterable
                      :style="{ width: '100%' }"
                      remote
                      size="mini"
                      @change="WOUpdate(remark.workOrderId, index)"
                      clearable
                      placeholder="Add Work Order"
                      :remote-method="workOrderMethod"
                      :loading="loading"
                    >
                      <el-option
                        v-for="item in availableWorkOrders"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8" align="left">
                    <el-input
                      v-model="remark.description"
                      placeholder="Description"
                      size="mini"
                      class="remark-input"
                      :readonly="true"
                    ></el-input>
                  </el-col>
                  <el-col :span="8" align="left">
                    <el-input
                      v-model="remark.text"
                      placeholder="Edit Remark"
                      size="mini"
                      class="remark-input"
                    ></el-input>
                  </el-col>
                  <el-select
                    v-model="remark.action"
                    placeholder="Select Action"
                    class="action-select"
                    size="mini"
                  >
                    <el-option
                      v-for="action in actions"
                      :key="action"
                      :label="action"
                      :value="action"
                    ></el-option>
                  </el-select>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="removeRemark(index)"
                    circle
                    size="mini"
                  ></el-button>
                </div>
                <el-button
                  type="success"
                  icon="el-icon-plus"
                  @click="addRemark"
                  size="mini"
                  class="add-remark-button"
                >
                  Add Remark
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleEditClose">Cancel</el-button>
        <el-button type="primary" @click="updatePPM">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { postgrest } from "../../api/postgrest";
import { workorder } from "../../api/workorder/workorder";

@Component({
  name: "PPMList",
})
export default class PPMList extends Vue {
  public filterFrom: string = "";
  public filterTo: string = "";
  public selectedHall: string = "";
  public selectedStatus: string = "";
  public selectedPeriodicity: string = "";
  public selectedSystem: string = "";
  public selectedSubsystem: string = "";
  public selectedForeman: string = "";
  public selectedSenTech: string = "";
  public selectedAction: string = "";
  public searchQuery: string = "";
  public tableSearchQuery: string = "";
  public components: any[] = [];
  public editDialogVisible: boolean = false;
  public editablePPM: any = null;
  public workOrderList: any[] = [];
  public availableWorkOrders: any[] = [];
  public loading: boolean = false;

  public halls: string[] = ["North", "South", "S+N"];
  public statuses: string[] = ["Completed", "Pending", "Cancelled"];
  public periodicities: string[] = [
    "Daily",
    "Bi-Weekly",
    "Monthly",
    "Six-Monthly",
    "Yearly",
    "Control - Monthly",
    "Control - Quarterly",
    "Control - Six-Monthly",
    "Control - Yearly",
  ];
  public systems: any[] = [];
  public subsystems: any[] = [];
  public foremen: any[] = [];
  public senTechs: any[] = [];
  public actions: string[] = ["TBM", "TBC", "TBR", "TBF"];

  public ppmList: any[] = [];
  public filteredPPMList: any[] = [];
  public dialogVisible: boolean = false;
  public selectedPPM: any = null;
  public scope: any;

  public async created() {
    await this.getSystems();
    await this.getSubSystems();
    await this.getSenTechs();
    await this.getForemen();
    await this.getPPMDetails();
    await this.getComponents();
    await this.getWorkOrderList();
  }

  public async getPPMDetails() {
    try {
      this.ppmList = await postgrest.get(
        "ppm_details",
        this.$store.getters.getToken
      );

      this.filteredPPMList = this.ppmList
        .map((ppm) => ({
          ...ppm,
          foremanName: this.getNameById(ppm.foreman, this.foremen),
          senTechName: this.getNameById(ppm.sen_tech, this.senTechs),
          systemName: this.getSystemNameById(ppm.system),
          // Use 'component' for yearly PPMs or else the subsystem name
          subsystemName:
            ppm.periodicity === "Yearly"
              ? ppm.component || "N/A"
              : this.getSubsystemNameById(ppm.subsystem),
        }))
        .filter((ppm) => !ppm.recycled);
    } catch (error) {
      console.error("Error fetching PPM Details:", error);
    }
  }

  public async getSenTechs() {
    try {
      this.senTechs = await postgrest.get(
        "aia_personnel",
        this.$store.getters.getToken
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  public async getSystems() {
    try {
      this.systems = await postgrest.get(
        "ppm_monthly_systems",
        this.$store.getters.getToken
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  public async getSubSystems() {
    try {
      this.subsystems = await postgrest.get(
        "ppm_monthly_subsystems",
        this.$store.getters.getToken
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  public async getForemen() {
    try {
      this.foremen = await postgrest.get(
        "personnel",
        this.$store.getters.getToken
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  public async getWorkOrderList() {
    try {
      const response = await workorder.getSomeWorkOrders(
        this.$store.getters.getToken,
        {},
        this.$store.getters.getHost
      );
      const resp = await response.json();
      this.workOrderList = resp.map((order: any) => ({
        value: order.aia_id.toString(),
        label: order.aia_id.toString(),
        key: order.aia_id,
        description: order.name,
      }));
    } catch (error) {
      console.error("Error fetching work orders:", error);
    }
  }

  public workOrderMethod(query: string) {
    if (query) {
      this.availableWorkOrders = this.workOrderList.filter((item) => {
        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
    } else {
      this.availableWorkOrders = [];
    }
  }

  public WOUpdate(workOrderId: string, index: number) {
    const selectedOrder = this.workOrderList.find(
      (order) => order.value === workOrderId
    );
    if (selectedOrder) {
      this.editablePPM.remarks[index].description = selectedOrder.description;
    }
  }

  public async viewDetails(ppm: any) {
    this.selectedPPM = {
      ...ppm,
      foremanName: this.getNameById(ppm.foreman, this.foremen),
      senTechName: this.getNameById(ppm.sen_tech, this.senTechs),
      systemName: this.getSystemNameById(ppm.system),
      subsystemName:
        ppm.periodicity === "Yearly"
          ? ppm.component || "N/A"
          : this.getSubsystemNameById(ppm.subsystem),
    };
    this.dialogVisible = true;
  }

  public async editPPM(ppm: any) {
    this.editablePPM = { ...ppm }; // Clone original data
    this.editDialogVisible = true;
  }

  public async updatePPM() {
    try {
      const updatedData = {
        hall: this.editablePPM.hall,
        system: this.editablePPM.system,
        subsystem: this.editablePPM.subsystem,
        status: this.editablePPM.status,
        techs: this.editablePPM.techs,
        remarks: this.editablePPM.remarks,
        completion_date:
          this.editablePPM.status === "Completed"
            ? this.editablePPM.completion_date
            : null,
        // Add other fields as needed
      };
      await postgrest.patch(
        `ppm_details?id=eq.${this.editablePPM.id}`,
        this.$store.getters.getToken,
        JSON.stringify(updatedData)
      );
      this.getPPMDetails();
      this.editDialogVisible = false;
    } catch (error) {
      console.error("Error updating PPM:", error);
    }
  }

  public async deletePPM(ppm: any) {
    try {
      const body = JSON.stringify({ recycled: true });
      await postgrest.patch(
        `ppm_details?id=eq.${ppm.id}`,
        this.$store.getters.getToken,
        body
      );
      this.getPPMDetails();
    } catch (error) {
      console.error("Error deleting PPM:", error);
    }
  }

  public filterData() {
    this.filteredPPMList = this.ppmList
      .filter((ppm) => {
        const matchesSearchQuery = Object.values(ppm).some((value) =>
          String(value)
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
        const matchesHall = this.selectedHall
          ? ppm.hall === this.selectedHall
          : true;
        const matchesStatus = this.selectedStatus
          ? ppm.status === this.selectedStatus
          : true;
        const matchesPeriodicity = this.selectedPeriodicity
          ? ppm.periodicity === this.selectedPeriodicity
          : true;
        const matchesSystem = this.selectedSystem
          ? ppm.system === this.selectedSystem
          : true;
        const matchesSubsystem = this.selectedSubsystem
          ? ppm.subsystem === this.selectedSubsystem
          : true;
        const matchesForeman = this.selectedForeman
          ? ppm.foreman === this.selectedForeman
          : true;
        const matchesSenTech = this.selectedSenTech
          ? ppm.sen_tech === this.selectedSenTech
          : true;
        const matchesAction = this.selectedAction
          ? ppm.remarks.some((remark) => remark.action === this.selectedAction)
          : true;

        const filterFromDate = this.filterFrom
          ? new Date(this.filterFrom)
          : null;
        const filterToDate = this.filterTo ? new Date(this.filterTo) : null;

        const ppmDate = new Date(ppm.date);
        const matchesDate =
          (filterFromDate ? ppmDate >= filterFromDate : true) &&
          (filterToDate
            ? ppmDate <= new Date(filterToDate.setHours(23, 59, 59, 999))
            : true);

        return (
          matchesSearchQuery &&
          matchesHall &&
          matchesStatus &&
          matchesPeriodicity &&
          matchesSystem &&
          matchesSubsystem &&
          matchesForeman &&
          matchesSenTech &&
          matchesAction &&
          matchesDate &&
          !ppm.recycled
        );
      })
      .map((ppm) => ({
        ...ppm,
        foremanName: this.getNameById(ppm.foreman, this.foremen),
        senTechName: this.getNameById(ppm.sen_tech, this.senTechs),
        systemName: this.getSystemNameById(ppm.system),
        subsystemName:
          ppm.periodicity === "Yearly"
            ? ppm.component || "N/A"
            : this.getSubsystemNameById(ppm.subsystem),
      }));
  }

  public get filteredTablePPMList() {
    if (!this.tableSearchQuery) return this.filteredPPMList;
    return this.filteredPPMList.filter((ppm) =>
      Object.values(ppm).some((value) =>
        String(value)
          .toLowerCase()
          .includes(this.tableSearchQuery.toLowerCase())
      )
    );
  }

  public get filteredSystems() {
    // Filter systems based on periodicity
    if (!this.editablePPM) return [];
    if (this.editablePPM.periodicity === "Daily") {
      return this.systems.filter(
        (system) => system.system === "General System"
      );
    } else if (this.editablePPM.periodicity === "Bi-Weekly") {
      return this.systems.filter(
        (system) => system.system === "Internal Tooling-Equipment"
      );
    }
    return this.systems;
  }

  public async getComponents() {
    try {
      this.components = await postgrest.get(
        "components",
        this.$store.getters.getToken
      );
      this.components = this.components.map((item: any) => ({
        id: item.id,
        literal: item.literal,
      }));
      // console.log(JSON.stringify(this.components));
    } catch (error) {
      console.error("Error fetching components:", error);
    }
  }

  public getNameById(id: number, list: any[]) {
    const person = list.find((item) => item.id === id);
    return person ? `${person.firstname} ${person.lastname}` : "Unknown";
  }

  public getSystemNameById(id: number) {
    const system = this.systems.find((system) => system.id === id);
    return system ? system.system : "Unknown";
  }

  public getSubsystemNameById(id: number) {
    const subsystem = this.subsystems.find((subsystem) => subsystem.id === id);
    return subsystem ? subsystem.subsystem : "";
  }

  public handleClose() {
    this.dialogVisible = false;
    this.selectedPPM = null;
  }

  public handleEditClose() {
    this.editDialogVisible = false;
    this.editablePPM = null;
  }

  public handleStatusChange(status: string) {
    if (status !== "Completed") {
      this.editablePPM.completion_date = null;
    }
  }

  public addRemark() {
    this.editablePPM.remarks.push({ text: "", action: "" });
  }

  public removeRemark(index: number) {
    this.editablePPM.remarks.splice(index, 1);
  }

  public addTech() {
    this.editablePPM.techs.push({
      id: this.editablePPM.techs.length + 1,
      label: "",
      date: "",
      start: "",
      end: "",
    });
  }

  public removeTech(index: number) {
    this.editablePPM.techs.splice(index, 1);
  }
}
</script>

<style scoped>
.container {
  padding: 20px;
  background-color: #e4e2e2;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

h2 {
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 2px solid #409eff;
  padding-bottom: 10px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-label {
  width: 100px;
  font-weight: bold;
}

.search-input,
.table-search-input {
  width: 100%;
  margin-bottom: 20px;
}

.hall-select,
.status-select,
.date-select,
.periodicity-select,
.system-select,
.foreman-select,
.sen-tech-select,
.action-select,
.subsystem-select {
  flex-grow: 1;
  width: 200px;
}

.el-tag {
  display: inline-block;
  line-height: 1;
}

.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 130px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}

strong {
  color: black;
}

.el-dialog {
  max-width: 800px;
  border-radius: 10px;
  padding: 20px;
}

.el-dialog__header {
  background-color: #409eff;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 20px;
}

.el-dialog__body {
  background-color: #f9f9f9;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.el-dialog__footer {
  padding: 10px 20px;
  border-top: 1px solid #ebeef5;
  background-color: #f9f9f9;
  text-align: right;
}

.dialog-content {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dialog-content strong {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.edit-dialog .el-form-item {
  margin-bottom: 15px;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tech-entry,
.remark-entry {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.tech-select,
.tech-time-input,
.remark-input {
  flex: 1;
}

.tech-date-picker {
  width: 150px;
}

.add-tech-button,
.add-remark-button {
  margin-top: 10px;
}
</style>
