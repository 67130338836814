<template>
  <div class="container">
    <h1>Shift Report Overview</h1>
    <h2>Details</h2>
    <el-table :data="shifts">
      <el-table-column prop="shift_date" label="Shift Date"></el-table-column>
      <el-table-column prop="shift_types.literal" label="Shift Type"></el-table-column>
      <el-table-column prop="foreman" label="Foreman"></el-table-column>
      <el-table-column prop="sentech" label="AIA Sen. Tech"></el-table-column>
    </el-table>

    <h2>System Information</h2>
    <el-table :data="formattedSystemInformation">
      <el-table-column prop="subject" label="Subject"></el-table-column>
      <el-table-column prop="content" label="Content"></el-table-column>
      <el-table-column
        prop="formatted_date_posted"
        label="Date Posted"
      ></el-table-column>
    </el-table>

    <h2>Internal Information</h2>
    <el-table :data="formattedInternalInformation">
      <el-table-column prop="subject" label="Subject"></el-table-column>
      <el-table-column prop="content" label="Content"></el-table-column>
      <el-table-column
        prop="formatted_date_posted"
        label="Date Posted"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { postgrest } from "../../api/postgrest";
import { Component, Vue, Emit } from "vue-property-decorator";

@Component({
  name: "ShiftOverview",
  components: {},
  props: {
    isDialog: {
      default: false,
    },
  },
  computed: {},
})
export default class ShiftOverview extends Vue {
  @Emit("close")
  test() {
    console.log("close called");
  }
  public shifts: any = [];
  public systemInformation: any = [];
  public internalInformation: any = [];
  public shift_types: any;

  public mounted() {
    console.log(this.$props.isDialog);
  }

  async created() {
    await this.getShifts();
  }

  public async getShifts() {
    try {
      this.shifts = await postgrest.get(
        "shifts?select=*,system_information(*),internal_information(*),shift_types(*)",
        this.$store.getters.getToken
      );

      console.log(JSON.stringify(this.shifts));

    //   this.shift_types = this.shifts.map((shift: any) => shift.shift_types);

    //   console.log(JSON.stringify(this.shift_types));

      this.systemInformation = this.shifts
        .map((shift: any) =>
          shift.system_information ? shift.system_information : null
        )
        .filter((info: any) => info !== null);

      this.internalInformation = this.shifts
        .map((shift: any) =>
          shift.internal_information ? shift.internal_information : null
        )
        .filter((info: any) => info !== null);
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  }

  get formattedSystemInformation() {
    return this.systemInformation.map((info: any) => ({
      ...info,
      formatted_date_posted: this.formatDate(info.date_posted),
    }));
  }

  get formattedInternalInformation() {
    return this.internalInformation.map((info: any) => ({
      ...info,
      formatted_date_posted: this.formatDate(info.date_posted),
    }));
  }

  public formatDate(date: string): string {
    return date.replace("T", " ");
  }
}
</script>

<style scoped></style>
