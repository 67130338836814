<template>
  <div>
    <el-row class="container">
      <WorkOrderFilters v-model="filters"></WorkOrderFilters>
      <br>
      <el-button @click="search" type="success">
        <strong class="white">Search</strong>
      </el-button>
    </el-row>
    <br>
    <el-row class="container">
      <el-table
        :data="workOrderList"
        :row-class-name="workOrderStatus"
        :default-sort="{prop: 'aia_id', order: 'descending'}"
        v-loading="loading"
        :header-cell-style="{background:'rgb(170, 238, 238)',color:'black', 'text-align':'center'}"
        border
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="personnel">
              <p>
                <u>Description:</u>
                {{props.row.name}}
              </p>
              <p>
                <u>Comments:</u>
                {{props.row.comments}}
              </p>
              <p>
                <u>Issue Date:</u>
                {{filterDate(props.row.date_issued)}}
              </p>
              <p>
                <u>End Date:</u>
                {{filterDate(props.row.date_closed)}}
              </p>
            </div>
            <div class="personnel">
              <p>
                <strong>Involved Personnel:</strong>
              </p>
            </div>
            <table style="font-weight:normal;">
              <tr>
                <th class="personnelHead">
                  <strong>Last Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>First Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>Date Started</strong>
                </th>
                <th class="personnelHead">
                  <strong>Date Ended</strong>
                </th>
              </tr>
              <tr v-for="p in JSON.parse(props.row.personnel)">
                <th class="personnel">{{personnelList.find(obj=>obj.id==p.id).lastname}}</th>
                <th class="personnel">{{personnelList.find(obj=>obj.id==p.id).firstname}}</th>
                <th class="personnel">{{filterDateTime(p.start_date)}}</th>
                <th class="personnel">{{filterDateTime(p.end_date)}}</th>
              </tr>
            </table>
            <el-button type="text" size="small" @click="exportWO(props.row)">Export</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="aia_id" label="WO_ID" sortable width="100px" align="center"></el-table-column>
        <el-table-column
          prop="componentIds[0]"
          label="Code"
          :formatter="getComponentName"
          align="center"
        ></el-table-column>
        <el-table-column prop="componentIds[0]" label="Hall" :formatter="getHall" align="center"></el-table-column>
        <el-table-column prop="name" label="Description" width="380px"></el-table-column>
        <el-table-column
          prop="status"
          :formatter="getStatus"
          label="Status"
          :filters="getFilters()"
          :filter-method="filterHandler"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="handleClick(scope.$index, scope.row)"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              v-if="userlevel==0"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteWO(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { component } from "../../api/component/component";
import { personnel } from "../../api/personnel/personnel";
import { workorder } from "../../api/workorder/workorder";
import { status } from "../../api/status/status";
import WorkOrderFilters from "../Filters/WorkOrderFilters.vue";
import Moment from "moment";
import { StringHelpers } from "../../api/helpers/StringHelpers";
@Component({
  name: "WorkOrderList",
  components: {
    WorkOrderFilters
  }
})
export default class WorkOrderList extends Vue {
  public workOrderList: any[] = [];
  public typeList: any[] = [];
  public statusList: any[] = [];
  public personnelList: any[] = [];
  public componentList: any[] = [];
  public loading: boolean = false;
  public filters: any = {};
  public userlevel: number = -1;
props: any;
scope: any;
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          console.log(this.personnelList);
          this.getStatusList();
        });
      });
  }
  public getComponentName(row: any, column: any): String {
    console.log(row);
    let component = this.componentList.find(
      (obj: any) => obj.id === row.componentIds[0]
    );
    if (component != undefined) {
      return component.literal;
    }
    return "";
  }
  public getHall(row: any, column: any): String {
    console.log(row);
    let component = this.componentList.find(
      (obj: any) => obj.id === row.componentIds[0]
    );
    if (component != undefined) {
      return component.group1;
    }
    return "";
  }
  public getStatus(row: any, column: any): String {
    console.log(row);
    let status = this.statusList.find((obj: any) => obj.id === row.status);
    if (status != undefined) {
      return status.literal;
    }
    return "";
  }
  public chopDescription(row: any, column: any): String {
    return StringHelpers.shorten(row.name);
  }
  public getFilters() {
    let a: any = [];
    {
      for (const s of this.statusList) {
        a.push({ value: s.id, text: s.literal });
      }
    }
    return a;
  }
  public async search() {
    this.loading = true;
    console.log(this.filters);
    if (this.filters.dateFrom === null) {
      delete this.filters.dateFrom;
    }
    if (this.filters.dateTo === null) {
      delete this.filters.dateTo;
    }
    await workorder
      .getSomeRecycledWorkOrders(
        this.$store.getters.getToken,
        this.filters,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.workOrderList = JSON.parse(JSON.stringify(resp));
          console.log(JSON.parse(JSON.stringify(resp)));
          this.loading = false;
        });
      });
  }
  public fillTable() {
    for (const w of this.workOrderList) {
      w.personnel = JSON.parse(w.personnel);
    }
    console.log(this.workOrderList);
  }
  public workOrderStatus({
    row,
    rowIndex
  }: {
    row: number;
    rowIndex: number;
  }): String {
    return this.getStatus(row, 1);
  }
  public async created() {
    this.userlevel = this.$store.getters.getUserLevel;
    await this.getPersonnelList();
  }
  public filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }
  public handleClick(rowIndex: number, row: any) {
    row.personnel = JSON.stringify(row.personnel);
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("setCurrentId", row.id);
    this.$store.dispatch("updateCurrentComponent", "AddWorkOrder");
    this.$router.replace({
      name: "load"
    });
  }
  public filterDate(date: any) {
    if (date === undefined || date === null || !Moment(date).isValid()) {
    return "";
  }
    return Moment(date).format("DD/MM/YYYY");
  }
  public filterDateTime(date: any) {
    if (date === undefined) {
      return "";
    }
    return Moment(date).format("DD/MM/YYYY HH:mm");
  }
  public async getComponentList() {
    await component
      .getAllComponents(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then(resp => {
          this.componentList = JSON.parse(JSON.stringify(resp));
        });
      });
    this.fillTable();
  }
  public async getWorkOrderList() {
    await workorder
      .getAllWorkOrders(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then(response => {
        response.json().then((resp: any) => {
          this.workOrderList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.statusList = JSON.parse(JSON.stringify(resp));
          this.getComponentList();
        });
      });
  }
  public async getWOTypeList() {
    await workorder
      .getAllTypes(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then((resp: any) => {
          this.typeList = JSON.parse(JSON.stringify(resp));
        });
      });
  }
  public exportWO(row: any) {
    this.$store.dispatch("setCurrentId", row.aia_id);
    this.$store.dispatch("updateCurrentComponent", "DownloadWorkOrderDetails");
    this.$router.replace({
      name: "load"
    });
  }
  public async deleteWO(rowIndex: number, row: any) {
    await workorder
      .deleteWorkOrder(
        this.$store.getters.getToken,
        row.id,
        this.$store.getters.getHost
      )
      .then(async r => this.search());
  }
}
</script>
<style>
.el-table .Pending {
  background: rgb(236, 255, 149);
}

.el-table .Completed {
  background: #ffffff;
}

.el-table .Cancelled {
  background: rgb(255, 186, 186);
}
</style>
<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
u {
  text-decoration: underline;
}
</style>