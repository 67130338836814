<template>
  <div>
    <el-row class="container">
      <el-row>
        <h2>Six-Monthly PPM Details</h2>
      </el-row>

      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select System:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="system"
            placeholder="Select System"
            size="mini"
            :style="{ width: '250px' }"
          >
            <el-option
              label="Heat-Smoke Detectors"
              value="Heat-Smoke Detectors"
            ></el-option>
            <el-option 
              label="Fire Panel" 
              value="Fire Panel">
          </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Hall:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="selectedHall"
            :style="{ width: '250px' }"
            placeholder="Select Hall"
            clearable
            size="mini"
          >
            <el-option
              v-for="item in halls"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Date:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-date-picker
            v-model="date"
            type="date"
            :style="{ width: '250px' }"
            placeholder="Select Date"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            size="mini"
          ></el-date-picker>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Foreman:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="foreman"
            filterable
            :style="{ width: '250px' }"
            remote
            size="mini"
            @change="clearSearch"
            clearable
            placeholder="Select Foreman"
            :remote-method="foremanMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableForemen"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select AIA Sen. Tech:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="senTech"
            filterable
            :style="{ width: '250px' }"
            remote
            size="mini"
            @change="clearSearch"
            clearable
            placeholder="Select AIA Sen. Tech"
            :remote-method="senTechMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableSenTechs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-row>
    <br />

    <el-row class="container">
      <el-row>
        <h2>Personnel</h2>
      </el-row>
      <el-row>
        <el-col :span="6">
          <div class="tag">
            <strong>Tech Name</strong>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="tag">
            <strong>Date</strong>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="tag">
            <strong>Time Started</strong>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="tag">
            <strong>Time Ended</strong>
          </div>
        </el-col>
      </el-row>
      <el-row v-for="(p, index) in techs" :key="index" v-if="techs[index].id">
        <el-col :span="6">
          <el-select
            v-model="techs[index].id"
            filterable
            :style="{ width: '220px' }"
            remote
            size="mini"
            @change="techUpdate(techs[index].id, index)"
            clearable
            placeholder="Add Technician"
            :remote-method="foremanMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableForemen"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-date-picker
            v-model="techs[index].date"
            type="date"
            placeholder="Date"
            size="mini"
            format="dd/MM/yyyy"
            :style="{ width: '150px' }"
          ></el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="00:00"
            v-on:input="startTimeChange(index)"
            v-model="techs[index].start"
            size="mini"
            maxlength="5"
            :style="{ width: '150px' }"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="00:00"
            v-on:input="endTimeChange(index)"
            v-model="techs[index].end"
            size="mini"
            maxlength="5"
            :style="{ width: '150px' }"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-select
            v-model="selectedTech"
            filterable
            @change="pushPersonnel"
            :style="{ width: '220px' }"
            remote
            size="mini"
            clearable
            placeholder="Add Technician"
            :remote-method="foremanMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableForemen"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-row>

    <br />

    <el-row class="container">
      <el-row>
        <h2>Inspection Remarks</h2>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6" align="left">
          <div class="tag">
            <strong>Remarks</strong>
          </div>
        </el-col>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Proposed Actions</strong>
          </div>
        </el-col>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Work Order ID</strong>
          </div>
        </el-col>
        <el-col :span="10" align="left">
          <div class="tag">
            <strong>Description</strong>
          </div>
        </el-col>
      </el-row>
      <el-row v-for="(remark, index) in remarksList" :key="index" :gutter="10">
        <el-col :span="6" align="left">
          <el-input
            type="textarea"
            v-model="remark.text"
            placeholder="Enter your remarks here"
            :rows="1"
            :style="{ width: 'calc(100% - 10px)', marginRight: '5px' }"
            @change="addRemarkLine(index)"
          ></el-input>
        </el-col>
        <el-col :span="4" align="left">
          <el-select
            v-model="remark.action"
            placeholder="Select Action"
            size="mini"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="action in proposed_action"
              :key="action"
              :label="action"
              :value="action"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="4" align="left">
          <el-select
            v-model="remark.workOrderId"
            filterable
            :style="{ width: '100%' }"
            remote
            size="mini"
            @change="WOUpdate(remark.workOrderId, index)"
            clearable
            placeholder="Add Work Order"
            :remote-method="workOrderMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in availableWorkOrders"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="10" align="left">
          <p
            v-if="remark.workOrderId"
            style="text-align: left; margin-top: 5px; padding: 5px; border: 1px solid rgb(0, 0, 0); width: 150%;"
          >
            {{ getWorkOrderDescription(remark.workOrderId) }}
          </p>
        </el-col>
      </el-row>
    </el-row>

    <br />

    <el-row class="container">
      <el-row>
        <h2>Status</h2>
      </el-row>

      <el-row>
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Select Status:</strong>
          </div>
        </el-col>
        <el-col :span="20" align="left">
          <el-select
            v-model="status"
            placeholder="Status"
            size="mini"
            :style="{ width: '200px' }"
            clearable
          >
            <el-option
              v-for="item in statuses"
              :key="item.id"
              :label="item.literal"
              :value="item.literal"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row v-if="status !== 'Pending'">
        <el-col :span="4" align="left">
          <div class="tag">
            <strong>Completion Date</strong>
          </div>
        </el-col>
        <el-col :span="12">
          <el-date-picker
            v-model="completionDate"
            type="date"
            placeholder="Completion Date"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            size="mini"
            :style="{ width: '200px' }"
          ></el-date-picker>
        </el-col>
      </el-row>
    </el-row>

    <el-row style="margin-top: 20px;">
      <el-button type="success" @click="submitPPMsixmonthly">Submit</el-button>
    </el-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { personnel } from "../../api/personnel/personnel";
import { aiaPersonnel } from "../../api/personnel/aiaPersonnel";
import PersonnelSelector from "../Selectors/PersonnelSelector.vue";
import StatusSelector from "../Selectors/StatusSelector.vue";
import { workorder } from "../../api/workorder/workorder";
import { postgrest } from "../../api/postgrest";

@Component({
  name: "AddPPMnew",
  components: {
    StatusSelector,
    PersonnelSelector,
  },
})
export default class AddPPMnew extends Vue {
  public system: string = "";
  public proposed_action: string[] = ["TBM", "TBC", "TBR", "TBF"];
  public halls: string[] = ["North", "South", "S+N"];
  public groups: any[] = [];
  public selectedHall: string = "";
  public date: any = "";
  public senTechList: any[] = [];
  public senTechs: any[] = [];
  public availableSenTechs: any[] = [];
  public senTech: any = "";
  public loading: boolean = false;
  public availableForemen: any[] = [];
  public personnelList: any[] = [];
  public foremen: any[] = [];
  public workers: any[] = [];
  public foreman: any = "";
  public techs: any[] = [];
  public standbyTechs: any[] = [];
  public personnel: any[] = [];
  public status: any = "";
  public completionDate: any = null;
  public selectedAction: any = "";
  public remarks: any = "";
  public workOrders: any[] = [];
  public availableWorkOrders: any[] = [];
  public selectedWorkOrder: any = "";
  public workOrderList: any[] = [];
  public selectedComponent = "";
  public workOrderDescription: string = "";
  public selectedTech: any = "";
  public ppm_daily: any[] = [];
  public statuses: any[] = [];
  public ppm_details: any;
  public periodicity: string = "Six-Monthly";
  public remarksList: any[] = [
    { text: "", action: "", workOrderId: "", description: "" },
  ];

  public async created() {
    await this.getSenTechList();
    await this.getPersonnelList();
    await this.getWorkOrderList();
    await this.getStatuses();
  }

  public senTechMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.availableSenTechs = this.senTechs.filter((item) => {
        return (
          item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.team == query
        );
      });
      this.loading = false;
    } else {
      this.availableSenTechs = [];
    }
  }

  public foremanMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.availableForemen = this.foremen
        .filter((item) => {
          return (
            (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
              item.team == query) &&
            this.foreman !== item.value &&
            !this.techs.some((r) => r.value === item.value) &&
            !this.standbyTechs.some((r) => r.value === item.value)
          );
        })
        .concat(
          this.workers.filter((item) => {
            return (
              (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                item.team == query) &&
              this.foreman !== item.value &&
              !this.techs.some((r) => r.value === item.value) &&
              !this.standbyTechs.some((r) => r.value === item.value)
            );
          })
        );
      console.log("------- " + JSON.stringify(this.availableForemen));
      this.loading = false;
    } else {
      this.availableForemen = [];
    }
  }

  public async submitPPMsixmonthly() {
    let filteredRemarks = this.remarksList.filter(
      (remark) =>
        remark.text !== "" ||
        remark.action !== "" ||
        remark.description !== "" ||
        remark.workOrderId !== ""
    );

    // Prepare PPM details
    this.ppm_details = {
      system: this.mapSystemToId(this.system),
      hall: this.selectedHall,
      date: this.date,
      foreman: this.foreman,
      sen_tech: this.senTech,
      techs: this.techs,
      remarks: filteredRemarks,
      status: this.status,
      recycled: false,
      periodicity: this.periodicity,
    };

    if (this.status !== "Pending") {
      this.ppm_details.completion_date = this.completionDate || null;
    }

    console.log(JSON.stringify(this.ppm_details));

    try {
      const token = this.$store.getters.getToken;
      const response = await postgrest.post(
        "ppm_details",
        token,
        this.ppm_details
      );
      console.log(response);
      this.$message.success({
        message: "PPM Six-Monthly Submitted Successfully",
        showClose: true,
      });

      this.$store.dispatch("updateCurrentObject", {});
      this.$store.dispatch("updateCurrentComponent", "PPMList");
      this.$router.replace({
        name: "load",
      });
    } catch (error) {
      console.log("Error posting data to PostgREST:", error);
    }
  }

  private mapSystemToId(system: string): number {
    switch (system) {
      case "Heat-Smoke Detectors":
        return 10;
      case "Fire Panel":
        return 11;
      default:
        return 0; // Default or error case
    }
  }

  public async getStatuses() {
    try {
      this.statuses = await postgrest.get(
        "status",
        this.$store.getters.getToken
      );
      console.log(JSON.stringify(this.statuses));
    } catch (error) {
      console.error("Error fetching PPM Daily data:", error);
    }
  }

  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then((response) => {
        response.json().then((resp) => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          for (const p of this.personnelList) {
            if (this.$store.getters.getUserLevel < 1 || p.active) {
              if (p.wo_specialty == 1) {
                this.foremen.push({
                  label:
                    p.firstname +
                    " " +
                    p.lastname +
                    (!p.active ? " (INACTIVE)" : ""),
                  value: p.id,
                  team: p.team,
                });
              } else {
                this.workers.push({
                  label:
                    p.firstname +
                    " " +
                    p.lastname +
                    (!p.active ? " (INACTIVE)" : ""),
                  value: p.id,
                  team: p.team,
                });
              }
            }
          }
        });
      });
  }

  public async getSenTechList() {
    await aiaPersonnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then((response) => {
        response.json().then((resp) => {
          this.senTechList = JSON.parse(JSON.stringify(resp));
          for (const p of this.senTechList) {
            this.senTechs.push({
              label: p.firstname + " " + p.lastname,
              value: p.id,
              team: p.team,
            });
          }
        });
      });
  }

  public async getWorkOrderList() {
    await workorder
      .getSomeWorkOrders(
        this.$store.getters.getToken,
        {},
        this.$store.getters.getHost
      )
      .then((response) => {
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.workOrderList.push({
              value: JSON.parse(JSON.stringify(resp))[i].aia_id.toString(),
              label: JSON.parse(JSON.stringify(resp))[i].aia_id.toString(),
              key: JSON.parse(JSON.stringify(resp))[i].aia_id,
              description: JSON.parse(JSON.stringify(resp))[i].name,
            });
            i++;
          }
        });
      });
  }

  public pushWorkOrder() {
    if (this.selectedWorkOrder) {
      const selected = this.workOrderList.find(
        (r) => r.value == this.selectedWorkOrder
      );
      if (selected) {
        this.workOrders.push({
          id: selected.value,
          description: selected.description,
        });
        this.selectedWorkOrder = "";
      }
    }
  }

  public workOrderMethod(query: string) {
    if (query) {
      this.availableWorkOrders = this.workOrderList.filter((item) => {
        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
    } else {
      this.availableWorkOrders = [];
    }
  }

  public clearSearch() {
    this.availableForemen = [];
  }

  public techUpdate(id: any, index: number) {
    if (id === "") {
      this.techs.splice(index, 1);
    }
  }

  public endTimeChange(index: number) {
    if (this.techs[index].end.length === 2) {
      this.techs[index].end += ":";
    }
  }

  public startTimeChange(index: number) {
    if (this.techs[index].start.length === 2) {
      this.techs[index].start += ":";
    }
  }

  public WOUpdate(id: any, index: number) {
    // Ensure the index is within bounds
    if (index >= 0 && index < this.remarksList.length) {
      if (id === "") {
        // Remove entry if id is empty
        this.remarksList[index].workOrderId = "";
        this.remarksList[index].description = "";
      } else {
        const selected = this.workOrderList.find((r) => r.value === id);
        if (selected) {
          // Set the description only if the entry exists
          this.remarksList[index].workOrderId = selected.value;
          this.remarksList[index].description = selected.description;
        }
      }
    } else {
      console.error("WOUpdate: Invalid index", index);
    }
  }

  public addRemarkLine(index: number) {
    if (this.remarksList[index].text !== "") {
      this.remarksList.push({
        text: "",
        action: "",
        workOrderId: "",
        description: "",
      });
    }
  }

  public getWorkOrderDescription(id: string) {
    const selected = this.workOrderList.find((r) => r.value == id);
    return selected ? selected.description : "";
  }

  public pushPersonnel() {
    const selectedPersonnel = this.personnelList.find(
      (r) => r.id == this.selectedTech
    );
    if (selectedPersonnel) {
      this.techs.push({
        id: this.selectedTech,
        value: this.selectedTech,
        label: `${selectedPersonnel.firstname} ${selectedPersonnel.lastname}`,
        start: "",
        end: "",
      });
      this.selectedTech = "";
    }
  }

  public handleSubmit() {
    const ppm_details = {
      system: this.system,
      hall: this.selectedHall,
      date: this.date,
      foreman: this.foreman,
      senTech: this.senTech,
      techs: this.techs,
      remarks: this.remarks,
      action: this.selectedAction,
      workOrders: this.workOrders,
      status: this.status,
      completionDate: this.completionDate,
    };
    console.log(JSON.stringify(ppm_details));
  }
}
</script>

<style scoped>
.container {
  padding: 20px;
  background-color: #e4e2e2;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

h2 {
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 2px solid #409eff;
  padding-bottom: 10px;
}

.tag {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.el-col {
  display: flex;
  align-items: center;
}

.el-col .el-select,
.el-col .el-date-picker,
.el-col .el-input {
  width: 100%;
}

.el-select .el-input__inner {
  border-radius: 4px;
  border-color: #409eff;
}

.el-date-picker .el-input__inner {
  border-radius: 4px;
  border-color: #409eff;
}

.el-input .el-textarea__inner {
  border-radius: 4px;
  border-color: #409eff;
}

.remarks-tag,
.actions-tag,
.workorder-tag {
  width: 100%;
}

.workorder-tag .tagText {
  margin-bottom: 5px;
}
</style>
