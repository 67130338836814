var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('el-row',{staticClass:"container"},[_c('el-input',{staticStyle:{"margin-bottom":"20px"},attrs:{"placeholder":"Search...","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('el-table',{attrs:{"data":_vm.filteredVacations,"row-class-name":_vm.vacationStatus1,"default-sort":{ prop: 'decision', order: 'ascending' },"header-cell-style":{
        background: 'rgb(170, 238, 238)',
        color: 'black',
        'text-align': 'center',
      },"border":""}},[_c('el-table-column',{attrs:{"label":"Name","sortable":"","formatter":_vm.getName,"width":"200px","align":"center","sort-method":(a, b) => _vm.sortName(a, b)}}),_c('el-table-column',{attrs:{"prop":"total_days_req","sortable":"","label":"Total days requested","width":"90px","align":"center","sort-method":(a, b) => _vm.sorttotalDateRequestedDate(a, b)}}),_c('el-table-column',{attrs:{"sortable":"","formatter":_vm.getleaveon,"label":"Leave on","width":"150px","align":"center","sort-method":(a, b) => _vm.sortDate(a, b)}}),_c('el-table-column',{attrs:{"sortable":"","formatter":_vm.getleaveend,"label":"Leave end","width":"150px","align":"center","sort-method":(a, b) => _vm.sortDatesortDateLeaveEnd(a, b)}}),_c('el-table-column',{attrs:{"sortable":"","formatter":_vm.getTypeOfvacation,"label":"Type","width":"130px","align":"center","sort-method":(a, b) => _vm.sortTypeOfvacation(a, b)}}),_c('el-table-column',{attrs:{"sortable":"","label":"Status","formatter":_vm.getStatus,"width":"110px","align":"center","sort-method":(a, b) => _vm.sortStatus(a, b)}}),_c('el-table-column',{attrs:{"label":"Actions","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.userlevel < 2)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-edit","circle":""},on:{"click":function($event){return _vm.Edit(scope.$index, scope.row)}}}):_vm._e(),(_vm.userlevel < 2)?_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.open(scope.$index, scope.row)}}}):_vm._e(),(_vm.userlevel < 2)?_c('el-button',{attrs:{"type":"warning","icon":"el-icon-printer","circle":""},on:{"click":function($event){return _vm.exportVacation(scope.$index, scope.row)}}}):_vm._e(),(
              _vm.userlevel >= 2 &&
                scope.row.decision !== 2 &&
                scope.row.decision !== 3
            )?_c('el-button',{attrs:{"type":"success","icon":"el-icon-check","circle":""},on:{"click":function($event){return _vm.addApproved(scope.$index, scope.row)}}}):_vm._e(),(
              _vm.userlevel >= 2 &&
                scope.row.decision !== 2 &&
                scope.row.decision !== 3
            )?_c('el-button',{attrs:{"type":"danger","icon":"el-icon-close","circle":""},on:{"click":function($event){return _vm.addRejected(scope.$index, scope.row)}}}):_vm._e()]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }