<template>
  <div>Downloading List 1</div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { vacation } from "../../api/vacation";
import moment from "moment";
@Component({ name: "DownloadVacation2" })
export default class DownloadVacation2 extends Vue {
  public async mounted() {
    let object = this.$store.getters.getCurrentObject;   
    let token = this.$store.getters.getToken;
    let year1 = object.year
    let id1 = object.id
    console.log("testfordownloadvacation2all")
    console.log(object)
    console.log(token)
    console.log(year1)
    console.log(id1)
    console.log("testfordownloadvacation2")
   
    //let year=this.$store.getters.getCurrentObject;
    //setCurrentId -1 gia epistrofi
    this.$store.dispatch("setCurrentId",-1 );
    //AddNewVacation arxiki othoni ekei pou thelw na giriso
    this.$store.dispatch("updateCurrentComponent", "Overview");
    this.$router.replace({
      name: "load"
    });       
    let response = await vacation.downloadVacation2(     
      token,
      id1,
      this.$store.getters.getHost,
      year1
    );
    response.blob().then((blob: any) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      //a.download = object.id.id + ".xlsx";
      if(id1==-2){
        console.log("printing Vacation")
        var CurrentDate = moment().format("DD/MM/YYYY")
        a.download = "BHS Vacation Records_"+ CurrentDate +".xlsx"; 
      }else if(id1==-1){
         console.log("printing Yearly")
        a.download ="Yearly_" + object.year+ ".xlsx"; 
      }else{
        console.log("printing personal leave")
        a.download = "Personal_Leave_" + object.year+ ".xlsx"; 
      }
           

      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  }
}
</script>
<style>
</style>