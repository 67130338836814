<template>
  <div class="container">
    <br><br><br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Select Employee(s):</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="name"
          filterable
          :style="{ width: '230px' }"
          remote
          size="mini"
          clearable
          placeholder="Name"
          value-key="id"
          :remote-method="nameMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableName"
            :key="item.id"
            :label="`${item.firstname} ${item.lastname}`"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Year:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input v-model="year" size="mini" :style="{ width: '230px' }"></el-input>
      </el-col>
    </el-row>
    <br><br>
    <el-row>
      <el-col :span="12" align="center">
        <el-button @click="handleAction('view')" style="margin-top: 12px;" type="success">
          <strong class="white">View Employees Leaves</strong>
        </el-button>
      </el-col>
      <el-col :span="12" align="center">
        <el-button @click="handleAction('export')" style="margin-top: 12px;" type="success">
          <strong class="white">Export Employees Leaves</strong>
        </el-button>
      </el-col>
    </el-row>
    <br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { personnel } from '../../api/personnel/personnel';
import { vacation } from '../../api/vacation';

@Component({
  name: 'Overview'
})
export default class Overview extends Vue {
  public id: any = '';
  public name: any = '';
  public year: any = '';
  public availableName: any[] = [];
  public personnelList: any[] = [];
  public loading: boolean = false;

  public async created() {
    await this.getPersonnelList();
    this.nameMethod('');
    this.$store.dispatch('updateCurrentObject', {});
  }

  public handleAction(action: string) {
    if (!this.name || !this.year) {
      this.$message.error('Please select an employee and enter a year.');
      return;
    }

    if (action === 'view') {
      this.viewEmployeeLeaves();
    } else if (action === 'export') {
      this.exportEmployeeLeaves();
    }
  }

  public async getPersonnelList() {
    try {
      const response = await personnel.getAllPersonnel(this.$store.getters.getToken, this.$store.getters.getHost);
      const data = await response.json();
      this.personnelList = [...data, { firstname: 'Yearly', lastname: ' ', id: -1 }, { firstname: 'Vacation', lastname: ' ', id: -2 }];
    } catch (error) {
      this.$message.error('Error fetching personnel list.');
    }
  }

  public nameMethod(query: string) {
    this.loading = true;
    this.availableName = this.personnelList.filter(item =>
      (item.firstname.toLowerCase().includes(query.toLowerCase()) ||
        item.lastname.toLowerCase().includes(query.toLowerCase())) &&
      (item.active || this.$store.getters.getUserLevel < 1)
    );
    this.loading = false;
  }

  public viewEmployeeLeaves() {
    this.$store.dispatch('updateCurrentObject', { year: this.year, id: this.name.id });
    this.$store.dispatch('updateCurrentComponent', 'ViewVacationSubmitted');
    this.$router.replace({ name: 'load' });
  }

  public exportEmployeeLeaves() {
    this.$store.dispatch('updateCurrentObject', { year: this.year, id: this.name.id });
    this.$store.dispatch('updateCurrentComponent', 'downloadVacation2');
    this.$router.replace({ name: 'load' });
  }
}
</script>

<style scoped>
strong {
  color: black;
}

.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 140px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}

.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px;
}
</style>
