<template>
  <div class="MainMenu">
    <h2>Personnel</h2>
    <h3>Personnel List</h3>
    <el-input v-model="search" placeholder="Type to search"></el-input>
    <el-row>
      <el-col>
        <el-table
          :data="manageData()"
          style="width: 100%"
          height="250"
          v-loading="loading"
          element-loading-spinner
          :row-class-name="activePer"
          element-loading-background="rgba(221, 218, 218, 1)"
        >
          <el-table-column prop="lastname" label="Last Name" width="220"></el-table-column>
          <el-table-column prop="firstname" label="First Name"></el-table-column>
          <el-table-column
            prop="company"
            label="Company"
            :filters="companies"
            :filter-method="filterHandler"
          ></el-table-column>
          <el-table-column prop="team" label="Group"></el-table-column>
          <el-table-column label="Actions">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleAdd(scope.$index, scope.row)">Add</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <h3>Selected Personnel</h3>
      <el-col :span="18">
        <el-table
          v-loading="loading"
          :data="tempPersonnel"
          height="290"
          ref="mainTable"
          :key="loading"
          :element-loading-text="loadText"
          element-loading-spinner
          element-loading-background="rgba(221, 218, 218, 1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="lastname" label="Last Name"></el-table-column>
          <el-table-column prop="firstname" label="First Name"></el-table-column>
          <el-table-column label="Actions">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleRemoveTemp(scope.$index, scope.row)">Remove</el-button>
            </template>
          </el-table-column>
          <el-table-column type="selection"></el-table-column>
        </el-table>
      </el-col>
      <el-col :span="6" class="timeselector">
        <el-row>
          <el-row>
            <el-tag size="mini">
              <strong>Date Started:</strong>
            </el-tag>
            <br />
            <el-date-picker
              v-model="startdategen"
              type="date"
              placeholder="Start Date"
              :style="{width:'150px'}"
              format="dd/MM/yyyy"
              size="mini"
              style="width:130px;"
            ></el-date-picker>
          </el-row>
          <el-row>
            <el-tag size="mini">
              <strong>Time Started:</strong>
            </el-tag>
            <br />
            <el-input
              placeholder="00:00"
              v-on:input="startTimeChange()"
              v-model="starttimegen"
              :style="{width:'150px'}"
              size="mini"
              maxlength="5"
              style="width:110px;"
            ></el-input>
          </el-row>
          <br />
          <el-row>
            <el-tag size="mini">
              <strong>Date Ended:</strong>
            </el-tag>
            <br />
            <el-date-picker
              v-model="stopdategen"
              type="date"
              placeholder="End Date"
              :style="{width:'150px'}"
              format="dd/MM/yyyy"
              size="mini"
              style="width:130px;"
            ></el-date-picker>
          </el-row>
          <el-row>
            <el-tag size="mini">
              <strong>Time Ended:</strong>
            </el-tag>
            <br />
            <el-input
              placeholder="00:00"
              v-on:input="endTimeChange()"
              v-model="endtimegen"
              :style="{width:'150px'}"
              size="mini"
              maxlength="5"
              style="width:110px;"
            ></el-input>
          </el-row>
          <br />
          <el-button @click="addDateAll()" size="mini">Add</el-button>
          <el-button @click="cancel()" size="mini">Cancel</el-button>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>Personnel Records</h3>
        <table style="font-weight:normal;font-size:14px" id="records">
          <tr>
            <th class="personnelHead">
              <el-button
                @click="sortTable(0)"
                type="primary"
                icon="el-icon-arrow-sort"
                :style="{width:'100%'}"
              >
                <strong class="white">Last Name</strong>
              </el-button>
            </th>
            <th class="personnelHead">
              <el-button
                @click="sortTable(1)"
                type="primary"
                icon="el-icon-arrow-sort"
                :style="{width:'100%'}"
              >
                <strong class="white">First Name</strong>
              </el-button>
            </th>
            <th class="personnelHead">
              <el-button
                @click="sortTable(2)"
                type="primary"
                icon="el-icon-arrow-sort"
                :style="{width:'100%'}"
              >
                <strong class="white">Date Started</strong>
              </el-button>
            </th>
            <th class="personnelHead">
              <el-button
                @click="sortTable(3)"
                type="primary"
                icon="el-icon-arrow-sort"
                :style="{width:'100%'}"
              >
                <strong class="white">Date Ended</strong>
              </el-button>
            </th>
            <th colspan="2" class="personnelHead">
              <strong class="white">Actions</strong>
            </th>
          </tr>
          <template v-for="p in $props.personnel">
            <tr v-for="d in p.dates">
              <th
                v-bind:class="tagType(p,d)"
              >{{personnelList.find(obj=>obj.id==p.id)!=undefined ? personnelList.find(obj=>obj.id==p.id).lastname:"undefined"}}</th>
              <th
                v-bind:class="tagType(p,d)"
              >{{personnelList.find(obj=>obj.id==p.id)!=undefined ? personnelList.find(obj=>obj.id==p.id).firstname:"undefined"}}</th>
              <th v-bind:class="tagType(p,d)">{{filterDateTime(d.date_started)}}</th>
              <th v-bind:class="tagType(p,d)">{{filterDateTime(d.date_ended)}}</th>
              <th class="personnel">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  @click="editDate(p.dates,d,p)"
                >Edit</el-button>
              </th>
              <th class="personnel">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="eraseDate(p.dates,d,p)"
                >Delete</el-button>
              </th>
            </tr>
          </template>
        </table>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { personnel } from "../../api/personnel/personnel";
import { StringHelpers } from "../../api/helpers/StringHelpers";
import { Company } from "../../api/personnel/company";
import { WOSpecialty } from "../../api/personnel/wospecialty";
import Moment from "moment";
@Component({
  name: "PersonnelSelector",
  props: {
    personnel: Array,
  },
  model: {
    prop: "personnel",
    event: "change",
  },
})
export default class PersonnelSelector extends Vue {
  public multipleSelection: any[] = [];
  public personnelList: any[] = [];
  search: string = "";
  companies: any[] = [];
  specialties: any[] = [];
  specialtyList: any[] = [];
  companyList: any[] = [];
  loading: boolean = true;
  dataview: any[] = [];
  loadText: string = "";
  dialogFormVisible: boolean = false;
  date: any[] = [];
  render: boolean = false;
  startdate: any = "";
  stopdate: any = "";
  starttime: any = "";
  endtime: any = "";
  startdategen: any = "";
  stopdategen: any = "";
  starttimegen: any = "";
  endtimegen: any = "";
  tempPersonnel: any[] = [];
  personUnderEdit: any = "";
  tempDateStart: any = "";
  tempDateStop: any = "";
  tempID: number = -1;
scope: any;
  public activePer({ row, rowIndex }: { row: number; rowIndex: number }): String {
    return this.getStatus(row, 1);
  }
  public getStatus(row: any, column: any): String {
    console.log(row);
    let person = this.personnelList.find((obj: any) => obj.id === row.id);
    if (person != undefined) {
      return person.active;
    }
    return "";
  }
  public sortTable(cell: number) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("records");
    switching = true;
    /*Make a loop that will continue until
  no switching has been done:*/
    while (switching) {
      //start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      console.log(rows);
      /*Loop through all table rows (except the
    first, which contains table headers):*/
      for (i = 1; i < rows.length - 1; i++) {
        //start by saying there should be no switching:
        shouldSwitch = false;
        /*Get the two elements you want to compare,
      one from current row and one from the next:*/
        x = rows[i].cells[cell];
        y = rows[i + 1].cells[cell];
        //check if the two rows should switch place:
        if (x.innerHTML > y.innerHTML) {
          //if so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }
  public filterDateTime(date: any) {
    if (date === undefined) {
      return "";
    }
    return Moment(date).format("DD/MM/YYYY HH:mm");
  }
  public tagType(person, date) {
    console.log(date);
    if (
      person == this.personUnderEdit &&
      this.filterDate(date.date_started) == this.filterDate(this.tempDateStart) &&
      this.filterDate(date.date_ended) == this.filterDate(this.tempDateStop)
    ) {
      return "personnelEdit";
    } else {
      return "personnel";
    }
  }
  public endTimeChange() {
    if (this.endtimegen.length === 2) {
      this.endtimegen = this.endtimegen + ":";
    }
  }
  public startTimeChange() {
    if (this.starttimegen.length === 2) {
      this.starttimegen = this.starttimegen + ":";
    }
  }
  public handleSelectionChange(val) {
    console.log("handle");
    this.multipleSelection = val;
    console.log(this.multipleSelection);
  }
  public addDateAll() {
    for (const p of this.tempPersonnel) {
      console.log(p);
      if (this.multipleSelection.includes(p)) {
        let valid = true;
        try {
          let starthour = this.starttimegen.split(":")[0];
          let startminute = this.starttimegen.split(":")[1];
          let endhour = this.endtimegen.split(":")[0];
          let endminute = this.endtimegen.split(":")[1];
          if (starthour < 0 || starthour > 23) {
            valid = false;
            this.starttimegen = "";
            this.$message.error("Invalid Start Hours");
          }
          if (valid && (endhour < 0 || endhour > 23)) {
            valid = false;
            this.endtimegen = "";
            this.$message.error("Invalid Start Hours");
          }
          if (valid && (startminute < 0 || startminute > 59)) {
            valid = false;
            this.starttimegen = "";
            this.$message.error("Invalid Start Minutes");
          }
          if (valid && (endminute < 0 || endminute > 59)) {
            valid = false;
            this.endtimegen = "";
            this.$message.error("Invalid End Minutes");
          }
          if ((this?.$parent?.$parent?.$options as any)?._componentTag != "AddAlarm") {
            if (valid && startminute % 15 != 0) {
              valid = false;
              this.starttimegen = "";
              this.$message.error("Valid Start Minutes are: 0,15,30,45");
            }
            if (valid && endminute % 15 != 0) {
              valid = false;
              this.endtimegen = "";
              this.$message.error("Valid End Minutes are: 0,15,30,45");
            }
          }
          let startdate = new Date(this.startdategen.setHours(starthour, startminute, 0, 0));
          let enddate = new Date(this.stopdategen.setHours(endhour, endminute, 0, 0));
          if (enddate.getTime() - startdate.getTime() < 0) {
            this.startdategen = "";
            this.stopdategen = "";
            this.$message.error("Start Date must be before End Date");
            valid = false;
          }
          let t = this.$props.personnel.find((r) => r.id == p.id);
          if (t != undefined) {
            if (valid) {
              if (
                t.dates.find(
                  (r) =>
                    this.filterDate(r.date_started) == this.filterDate(startdate) &&
                    this.filterDate(r.date_ended) == this.filterDate(enddate)
                ) != undefined
              ) {
                this.$message.error("Date already exists!");
              } else {
                console.log(this.tempID);
                if (this.tempID == -1) {
                  t.dates.push({
                    date_started: startdate,
                    date_ended: enddate,
                  });
                } else {
                  t.dates.splice(this.tempID, 1, {
                    date_started: startdate,
                    date_ended: enddate,
                  });
                  this.tempID = -1;
                }
                if (this.tempDateStart != undefined || this.tempDateStart != "") {
                  /*t.dates.splice(
                    t.dates.indexOf(
                      t.dates.find(
                        r =>
                          this.filterDate(r.date_started) ==
                          this.filterDate(this.tempDateStart)
                      )
                    ),
                    1
                  );*/
                }
              }
            }
          } else {
            if (valid) {
              p.dates = [];
              p.dates.push({
                date_started: startdate,
                date_ended: enddate,
              });
              this.$props.personnel.push(p);
            }
          }
        } catch (e) {
          console.log(e);
          this.starttimegen = "";
          this.endtimegen = "";
          this.startdategen = "";
          this.stopdategen = "";
          this.$message.error("Invalid Dates");
        }
      }
    }
    this.personUnderEdit = "";
    this.starttimegen = "";
    this.endtimegen = "";
    this.startdategen = "";
    this.stopdategen = "";
    for (const p of this.multipleSelection) {
      this.tempPersonnel.splice(this.tempPersonnel.indexOf(p), 1);
    }

    (this.$refs.mainTable as any).clearSelection();
    console.log(this.$props.personnel);
    this.loading = false;
  }
  public cancel() {
    let p = this.$props.personnel.find((r) => r.id == this.personUnderEdit.id);
    this.personUnderEdit = "";
    this.loading = false;
    for (const p of this.multipleSelection) {
      this.tempPersonnel.splice(this.tempPersonnel.indexOf(p), 1);
    }
    this.starttimegen = "";
    this.endtimegen = "";
    this.startdategen = "";
    this.stopdategen = "";
    this.tempDateStart = "";
    this.tempDateStop = "";
    this.tempID = -1;
  }
  public editDate(dates?: any, date?: any, person?: any) {
    this.tempID = dates.indexOf(date);
    console.log(this.tempID);
    this.loading = true;
    this.personUnderEdit = person;
    console.log("edit");
    (this.$refs.mainTable as any).clearSelection();
    this.tempPersonnel.push({
      id: person.id,
      firstname: person.firstname,
      lastname: person.lastname,
    });
    this.loadText = "Please complete edits on " + person.firstname + " " + person.lastname;
    for (const p of this.tempPersonnel) {
      if (p.id == person.id) {
        (this.$refs.mainTable as any).toggleRowSelection(p);
      }
    }
    this.startdategen = new Date(date.date_started);
    this.stopdategen = new Date(date.date_ended);
    this.starttimegen = this.startdategen.getHours() + ":" + this.startdategen.getMinutes();
    this.endtimegen = this.stopdategen.getHours() + ":" + this.stopdategen.getMinutes();
    if (this.startdategen.getMinutes() == 0) {
      this.starttimegen += "0";
    }
    if (this.stopdategen.getMinutes() == 0) {
      this.endtimegen += "0";
    }
    this.tempDateStart = new Date(date.date_started);
    this.tempDateStop = new Date(date.date_ended);
  }

  public eraseDate(dates?: any, date?: any, person?: any) {
    if (dates.length != 1) {
      this.$confirm(
        "This will permanently delete " +
          person.firstname +
          " " +
          person.lastname +
          " " +
          Moment(date).format("DD-MM-YYYY HH:mm") +
          ". Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "Delete completed",
          });
          this.personUnderEdit = "";
          this.tempPersonnel.splice(this.tempPersonnel.indexOf(person), 1);
          if (person.dates.length == 1) {
          } else {
            person.dates.splice(person.dates.indexOf(date), 1);
          }

          this.tempID = -1;
          this.loading = false;
          this.starttimegen = "";
          this.endtimegen = "";
          this.startdategen = "";
          this.stopdategen = "";
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    } else {
      this.handleRemove(this.$props.personnel.indexOf(person), person);
    }
  }

  public fillTable() {
    this.personUnderEdit = "";
    this.tempPersonnel = [];
    for (const p of this.$props.personnel) {
      console.log(this.personnelList);
      let person = this.personnelList.find((obj) => obj.id === p.id);
      p.firstname = person.firstname;
      p.lastname = person.lastname;
    }
    for (const tempPerson of this.personnelList) {
      tempPerson.company = this.companyList.find((obj) => obj.id === tempPerson.company_id).name;
      tempPerson.specialty = StringHelpers.capitalize(
        this.specialtyList.find((obj) => obj.id === tempPerson.wo_specialty).literal
      );
      if (!this.companies.some((e) => e.text === tempPerson.company)) {
        this.companies.push({
          text: tempPerson.company,
          value: tempPerson.company,
        });
      }
      if (!this.specialties.some((e) => e.text === tempPerson.specialty)) {
        this.specialties.push({
          text: tempPerson.specialty,
          value: tempPerson.specialty,
        });
      }
      this.dataview.push(tempPerson);
    }
    this.loading = false;
  }
  public async created() {
    // console.log(this.$parent.$parent.$options._componentTag);
    this.loading = true;
    this.getCompanyList().then((r) => {
      this.getPersonnelList().then((r) => {
        this.getSpecialtyList();
      });
    });
  }
  public filterHandler(value: string, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }

  public filterHandlerAdded(value: string, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }
  public manageData() {
    var view = this.dataview.filter(
      (data) =>
        (!this.search ||
          data.lastname.toLowerCase().includes(this.search.toLowerCase()) ||
          data.firstname.toLowerCase().includes(this.search.toLowerCase()) ||
          data.team == this.search) &&
        (this.$store.getters.getUserLevel < 1 || data.active)
    );
    return view;
  }
  public handleAdd(index, row) {
    if (this.tempPersonnel.find((r) => r.id == row.id) == undefined) {
      this.tempPersonnel.push({
        id: row.id,
        firstname: row.firstname,
        lastname: row.lastname,
      });
      for (const p of this.tempPersonnel) {
        if (p.id == row.id) {
          (this.$refs.mainTable as any).toggleRowSelection(p);
        }
      }
    }
  }
  handleRemoveTemp(index, row) {
    this.tempPersonnel.splice(index, 1);
  }
  public handleRemove(index, row) {
    this.$confirm("This will permanently delete " + row.firstname + " " + row.lastname + ". Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        this.$message({
          type: "success",
          message: "Delete completed",
        });
        this.$props.personnel.splice(index, 1);
        row.dates = [];
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete canceled",
        });
      });
  }
  public async getSpecialtyList() {
    await WOSpecialty.getAllWOSpecialties(this.$store.getters.getToken, this.$store.getters.getHost).then(
      (response) => {
        response.json().then((resp) => {
          this.specialtyList = JSON.parse(JSON.stringify(resp));
          this.fillTable();
        });
      }
    );
  }
  public async getCompanyList() {
    await Company.getAllCompanies(this.$store.getters.getToken, this.$store.getters.getHost).then((response) => {
      response.json().then((resp) => {
        this.companyList = JSON.parse(JSON.stringify(resp));
      });
    });
  }
  public async getPersonnelList() {
    await personnel.getAllPersonnel(this.$store.getters.getToken, this.$store.getters.getHost).then((response) => {
      response.json().then((resp) => {
        this.personnelList = JSON.parse(JSON.stringify(resp));
      });
    });
  }
  public filterDate(date: any) {
    return Moment(date).format("DD-MM-YYYY HH:mm");
  }
}
</script>
<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
strong {
  color: black;
}
.timeselector {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 2px 15px 2px 15px;
  background-color: rgb(223, 233, 232);
  height: 290px;
}
.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelEdit {
  background-color: rgb(255, 216, 88);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelHead {
  font-weight: normal !important;
  background-color: #409eff;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.white {
  color: white;
}
.el-table .false {
  background: rgb(255, 186, 186);
}
</style>
