<template>
  <div>
    <h3>Shift Report</h3>
    <br />
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Shift Date:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-date-picker
          v-model="date"
          type="date"
          :style="{ width: '230px' }"
          placeholder="Shift Date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
          size="mini"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Shift Type:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="type"
          :style="{ width: '230px' }"
          placeholder="Shift Type"
          :items="types"
          size="mini"
        >
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>AIA Sen. Tech:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="senTech"
          filterable
          :style="{ width: '230px' }"
          remote
          size="mini"
          @change="clearSearch()"
          clearable
          placeholder="AIA Sen. Tech"
          :remote-method="senTechMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableSenTechs"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Foreman:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="foreman"
          filterable
          :style="{ width: '230px' }"
          remote
          size="mini"
          @change="clearSearch()"
          clearable
          placeholder="Foreman"
          :remote-method="foremanMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableForemen"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row v-for="(p, index) in techs" v-if="techs[index].id != ''">
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Tech {{ index + 1 }}:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-select
          v-model="techs[index].id"
          filterable
          :style="{ width: '230px' }"
          remote
          size="mini"
          @change="techUpdate(techs[index].id, index)"
          clearable
          placeholder="Technician"
          :remote-method="foremanMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableForemen"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-tag size="mini">
          <strong>Time Started:</strong>
        </el-tag>
        <el-input
          placeholder="00:00"
          v-on:input="startTimeChange(index)"
          v-model="techs[index].start"
          :style="{ width: '150px' }"
          size="mini"
          maxlength="5"
          style="width:110px;"
        ></el-input>
      </el-col>
      <el-col :span="6">
        <el-tag size="mini">
          <strong>Time Ended:</strong>
        </el-tag>
        <el-input
          placeholder="00:00"
          v-on:input="endTimeChange(index)"
          v-model="techs[index].end"
          :style="{ width: '150px' }"
          size="mini"
          maxlength="5"
          style="width:110px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row v-if="type != ''">
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Tech {{ techs.length + 1 }}:</strong>
        </div>
      </el-col>
      <el-col :span="10" align="left">
        <el-select
          v-model="selectedTech"
          filterable
          @change="pushPersonnel"
          :style="{ width: '230px' }"
          remote
          size="mini"
          clearable
          placeholder="Technician"
          :remote-method="foremanMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableForemen"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row
      v-for="(p, index) in standbyTechs"
      v-if="standbyTechs[index].id != ''"
    >
      <el-col :span="6" align="left">
        <div class="standbyTag">
          <strong>Standby Tech {{ index + 1 }}:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-select
          v-model="standbyTechs[index].id"
          filterable
          :style="{ width: '230px' }"
          remote
          size="mini"
          @change="standbyTechUpdate(standbyTechs[index].id, index)"
          clearable
          placeholder="Technician"
          :remote-method="foremanMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableForemen"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-tag size="mini">
          <strong>Time Started:</strong>
        </el-tag>
        <el-input
          placeholder="00:00"
          v-on:input="startTimeStandbyChange(index)"
          v-model="standbyTechs[index].start"
          :style="{ width: '150px' }"
          size="mini"
          maxlength="5"
          style="width:110px;"
        ></el-input>
      </el-col>
      <el-col :span="6">
        <el-tag size="mini">
          <strong>Time Ended:</strong>
        </el-tag>
        <el-input
          placeholder="00:00"
          v-on:input="endTimeStandbyChange(index)"
          v-model="standbyTechs[index].end"
          :style="{ width: '150px' }"
          size="mini"
          maxlength="5"
          style="width:110px;"
        ></el-input>
      </el-col>
    </el-row>
    <el-row v-if="type != ''">
      <el-col :span="6" align="left">
        <div class="standbyTag">
          <strong>Standby Tech {{ standbyTechs.length + 1 }}:</strong>
        </div>
      </el-col>
      <el-col :span="10" align="left">
        <el-select
          v-model="selectedStandbyTech"
          filterable
          @change="pushStandbyPersonnel"
          :style="{ width: '230px' }"
          remote
          size="mini"
          clearable
          placeholder="Technician"
          :remote-method="foremanMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableForemen"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <br />
    <hr />

    <!-- Work Orders Section -->
    <h2>Work Orders</h2>
    <el-button type="danger" @click="addWorkOrder">Add Work Order</el-button>

    <!-- Work Order Dialog -->
    <el-dialog
      title="Work Order"
      :visible.sync="showWorkOrderDialog"
      width="80%"
      @close="onWorkOrderDialogClose"
    >
      <!-- Include the AddWorkOrder component inside the dialog -->
      <AddWorkOrder @close="onWorkOrderDialogClose" />
    </el-dialog>
    <br />

    <hr />

    <!-- BHS System PPM Section -->
    <h2>BHS System PPM</h2>
    <el-row>
      <el-button type="primary" @click="addPPM('daily')">Add Daily</el-button>
      <el-button type="primary" @click="addPPM('weekly')">Add Weekly</el-button>
      <el-button type="primary" @click="addPPM('monthly')"
        >Add Monthly</el-button
      >
      <el-button type="primary" @click="addPPM('quarterly')"
        >Add Quarterly</el-button
      >
      <el-button type="primary" @click="addPPM('sixMonthly')"
        >Add Six-Monthly</el-button
      >
      <el-button type="primary" @click="addPPM('yearly')">Add Yearly</el-button>
    </el-row>
    <br />

    <hr />

    <!-- BHS Control PPM Section -->
    <h2>BHS Control PPM</h2>
    <el-row>
      <el-button type="warning" @click="addPPMControl"
        >Add PPM Control</el-button
      >
    </el-row>
    <el-dialog
      title="Add PPM Control"
      :visible.sync="showAddPPM_ControlDialog"
      width="80%"
      @close="onDialogClose"
    >
      <AddPPM_control
        :isDialog="true"
        @added="onDialogClose"
        @close="onDialogClose"
      />
    </el-dialog>
    <br />

    <hr />
    <!-- System Information Section -->
    <h2>System Information</h2>
    <el-button type="info" @click="addSystemInformation"
      >Add System Information</el-button
    >
    <el-dialog
      title="System Information"
      :visible.sync="showSystemInformationDialog"
      width="60%"
      @close="onDialogClose"
    >
      <AddSystemInformation
        :isDialog="true"
        @added="onDialogClose"
        @close="onDialogClose"
      />
    </el-dialog>
    <br />

    <hr />
    <!-- Internal Information Section -->
    <h2>Internal Information</h2>
    <el-button type="info" @click="addInternalInformation"
      >Add Internal Information</el-button
    >
    <el-dialog
      title="Internal Information"
      :visible.sync="showInternalInformationDialog"
      width="60%"
      @close="onDialogClose"
    >
      <AddInternalInformation :isDialog="true" @close="onDialogClose" />
    </el-dialog>
    <br />

    <hr />

    <el-button @click.native="addToDB" type="success">
      <strong class="white">SUBMIT</strong>
    </el-button>
  </div>
</template>

<script lang="ts">
import { personnel } from "../../api/personnel/personnel";
import { shifts } from "../../api/shifts/shifts";
import { Component, Vue, Prop } from "vue-property-decorator";
import { aiaPersonnel } from "../../api/personnel/aiaPersonnel";
import AddSystemInformation from "../../parts/Adders/AddSystemInformation.vue";
import AddWorkOrder from "../../parts/Adders/AddWorkOrder.vue";
import AddInternalInformation from "../../parts/Adders/AddInternalInformation.vue";
import ShiftOverview from "../Details/ShiftOverview.vue";
import AddPPM_control from "../../parts/Adders/AddPPM_control.vue";
import Moment from "moment";
import { copyFile } from "fs";

@Component({
  name: "AddNewShiftReport",
  components: {
    AddSystemInformation, // Register the AddSystemInformation component
    AddWorkOrder,
    AddInternalInformation,
    ShiftOverview,
    AddPPM_control
  },
})
export default class AddShift extends Vue {
  public personnelList: any[] = [];
  public types: any[] = [];
  public type: any = "";
  public date: any = "";
  public loading: boolean = false;
  public availableForemen: any[] = [];
  public availablePersonnel: any[] = [];
  public foremen: any[] = [];
  public workers: any[] = [];
  public foreman: any = "";
  public selectedPersonnel: any[] = [];
  public techs: any[] = [];
  public selectedTech: any = "";
  public standbyTechs: any[] = [];
  public selectedStandbyTech: any = "";
  public row: any = "";
  public senTechList: any[] = [];
  public senTechs: any[] = [];
  public availableSenTechs: any[] = [];
  public senTech: any = "";
  public showWorkOrderDialog: boolean = false;
  public showSystemInformationDialog: boolean = false;
  public showInternalInformationDialog: boolean = false;
  public showShiftOverviewDialog: boolean = false;
  public showAddPPM_ControlDialog: boolean = false;

  showShiftOverview() {
    this.showShiftOverviewDialog = true;
  }

  // Method to handle button click and open the dialog
  addSystemInformation() {
    this.showSystemInformationDialog = true;
  }

  addInternalInformation() {
    this.showInternalInformationDialog = true;
  }

  addPPMControl() {
    this.showAddPPM_ControlDialog = true;
  }

  // Method to handle dialog close event
  onDialogClose() {
    this.showSystemInformationDialog = false;
    this.showInternalInformationDialog = false;
    this.showShiftOverviewDialog = false;
    this.showAddPPM_ControlDialog = false;
  }

  onInternalInformationClose() {
    this.showInternalInformationDialog = false;
  }

  addWorkOrder() {
    this.showWorkOrderDialog = true;
  }

  onWorkOrderDialogClose() {
    this.showWorkOrderDialog = false;
  }

  addPPM(type) {
    console.log(`Add ${type} PPM clicked`);
  }

  addControlPPM(type) {
    console.log(`Add control PPM ${type} clicked`);
  }

  public techUpdate(id: any, index: number) {
    if (id == "") {
      this.techs.splice(index, 1);
    }
    console.log(this.techs);
  }
  public pushPersonnel() {
    this.techs.push({
      id:
        this.personnelList.find((r) => r.id == this.selectedTech).firstname +
        " " +
        this.personnelList.find((r) => r.id == this.selectedTech).lastname,
      value: this.selectedTech,
      start: this.types.find((r) => r.value == this.type).start,
      end: this.types.find((r) => r.value == this.type).end,
    });
    this.selectedTech = "";
    this.availableForemen = [];
    this.availablePersonnel = [];
    console.log(this.techs);
  }
  public foremanMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableForemen = this.foremen.filter((item) => {
        return (
          (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
            item.team == query) &&
          this.foreman != item.value &&
          this.techs.find((r) => r.value == item.value) == undefined &&
          this.standbyTechs.find((r) => r.value == item.value) == undefined
        );
      });
      this.availableForemen = this.availableForemen.concat(
        this.workers.filter((item) => {
          return (
            (item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
              item.team == query) &&
            this.foreman != item.value &&
            this.techs.find((r) => r.value == item.value) == undefined &&
            this.standbyTechs.find((r) => r.value == item.value) == undefined
          );
        })
      );
      // console.log('888 ' + JSON.stringify(this.availableForemen));
    } else {
      this.availableForemen = [];
    }
  }
  public senTechMethod(query: string) {
    if (query != "" && query != undefined) {
      this.loading = true;
      this.loading = false;
      this.availableSenTechs = this.senTechs.filter((item) => {
        return (
          item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.team == query
        );
      });
      console.log(this.availableForemen);
    } else {
      this.availableSenTechs = [];
    }
  }
  public techMethod(query: string) {
    if (query != "") {
      this.loading = true;
      this.loading = false;
      this.availablePersonnel = this.workers.filter((item) => {
        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      });
      console.log(this.availablePersonnel);
    } else {
      this.availablePersonnel = [];
    }
  }
  public endTimeChange(index: number) {
    if (this.techs[index].end.length === 2) {
      this.techs[index].end = this.techs[index].end + ":";
    }
  }
  public startTimeChange(index: number) {
    if (this.techs[index].start.length === 2) {
      this.techs[index].start = this.techs[index].start + ":";
    }
  }
  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then((response) => {
        response.json().then((resp) => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          // console.log('777' + JSON.stringify(this.personnelList))
          for (const p of this.personnelList) {
            if (this.$store.getters.getUserLevel < 1 || p.active) {
              if (p.wo_specialty == 1) {
                this.foremen.push({
                  label:
                    p.firstname +
                    " " +
                    p.lastname +
                    (!p.active ? " (INACTIVE)" : ""),
                  value: p.id,
                  team: p.team,
                });
              } else {
                console.log("777- adding" + JSON.stringify(p));
                this.workers.push({
                  label:
                    p.firstname +
                    " " +
                    p.lastname +
                    (!p.active ? " (INACTIVE)" : ""),
                  value: p.id,
                  team: p.team,
                });
              }
            }
          }
        });
      });
  }

  public async getSenTechList() {
    await aiaPersonnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then((response) => {
        response.json().then((resp) => {
          this.senTechList = JSON.parse(JSON.stringify(resp));
          for (const p of this.senTechList) {
            this.senTechs.push({
              label: p.firstname + " " + p.lastname,
              value: p.id,
              team: p.team,
            });
          }
          console.log(this.senTechs);
        });
      });
  }

  public clearSearch() {
    this.availableForemen = [];
  }

  public async getShiftTypeList() {
    await shifts
      .getShiftTypes(this.$store.getters.getToken, this.$store.getters.getHost)
      .then((response) => {
        response.json().then((resp: any) => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.types.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label:
                JSON.parse(JSON.stringify(resp))[i].literal +
                " (" +
                JSON.parse(JSON.stringify(resp))[i].time_start +
                " - " +
                JSON.parse(JSON.stringify(resp))[i].time_end +
                ")",
              start: JSON.parse(JSON.stringify(resp))[i].time_start,
              end: JSON.parse(JSON.stringify(resp))[i].time_end,
            });
            i++;
          }
        });
      });
  }

  public async created() {
    await this.getPersonnelList();
    await this.getShiftTypeList();
    await this.getSenTechList();
    this.checkEdit();
    console.log(this.type);
  }

  public checkEdit() {
    this.row = this.$store.getters.getCurrentObject;
    if (this.row.id != undefined) {
      console.log(this.row);
      this.type = this.row.type;
      this.date = this.row.shift_date;
      this.foreman =
        this.row.foreman.firstname + " " + this.row.foreman.lastname;
      if (this.row.senTech != undefined) {
        this.senTech =
          this.row.senTech.firstname + " " + this.row.senTech.lastname;
      }

      for (const p of JSON.parse(this.row.personnel)) {
        console.log(p);
        if (!p.standby) {
          this.techs.push({
            id:
              this.personnelList.find((r) => r.id == p.personnel_id).firstname +
              " " +
              this.personnelList.find((r) => r.id == p.personnel_id).lastname,
            value: p.personnel_id,
            start:
              (new Date(p.date_started).getHours() < 10
                ? "0" + new Date(p.date_started).getHours()
                : new Date(p.date_started).getHours()) +
              ":" +
              (new Date(p.date_started).getMinutes() < 10
                ? "0" + new Date(p.date_started).getMinutes()
                : new Date(p.date_started)),
            end:
              (new Date(p.date_ended).getHours() < 10
                ? "0" + new Date(p.date_ended).getHours()
                : new Date(p.date_ended).getHours()) +
              ":" +
              (new Date(p.date_ended).getMinutes() < 10
                ? "0" + new Date(p.date_ended).getMinutes()
                : new Date(p.date_ended)),
          });
        } else {
          this.standbyTechs.push({
            id:
              this.personnelList.find((r) => r.id == p.personnel_id).firstname +
              " " +
              this.personnelList.find((r) => r.id == p.personnel_id).lastname,
            value: p.personnel_id,
            start:
              (new Date(p.date_started).getHours() < 10
                ? "0" + new Date(p.date_started).getHours()
                : new Date(p.date_started).getHours()) +
              ":" +
              (new Date(p.date_started).getMinutes() < 10
                ? "0" + new Date(p.date_started).getMinutes()
                : new Date(p.date_started)),
            end:
              (new Date(p.date_ended).getHours() < 10
                ? "0" + new Date(p.date_ended).getHours()
                : new Date(p.date_ended).getHours()) +
              ":" +
              (new Date(p.date_ended).getMinutes() < 10
                ? "0" + new Date(p.date_ended).getMinutes()
                : new Date(p.date_ended)),
          });
        }
      }
    }
  }
  public print() {
    console.log(this.techs);
    console.log(this.type);
  }
  public beforeDestroy() {
    this.$store.dispatch("updateCurrentObject", {});
  }
  public addToDB() {
    let techsToAdd: any[] = [];
    for (const tech of this.techs) {
      let t = JSON.parse(JSON.stringify(tech));
      let starthour = t.start.split(":")[0];
      let startminute = t.start.split(":")[1];
      let endhour = t.end.split(":")[0];
      let endminute = t.end.split(":")[1];
      t.start = new Date(
        new Date(this.date).setHours(starthour, startminute, 0, 0)
      );
      t.end = new Date(new Date(this.date).setHours(endhour, endminute, 0, 0));
      t.id = t.value;
      t.standby = false;
      techsToAdd.push(t);
    }
    for (const tech of this.standbyTechs) {
      let t = JSON.parse(JSON.stringify(tech));
      let starthour = t.start.split(":")[0];
      let startminute = t.start.split(":")[1];
      let endhour = t.end.split(":")[0];
      let endminute = t.end.split(":")[1];
      t.start = new Date(
        new Date(this.date).setHours(starthour, startminute, 0, 0)
      );
      t.end = new Date(new Date(this.date).setHours(endhour, endminute, 0, 0));
      t.id = t.value;
      t.standby = true;
      techsToAdd.push(t);
    }
    console.log("999 " + this.date);
    let shiftItem: any[] = [
      {
        shift_date: this.date,
        foreman: isNaN(this.foreman)
          ? this.personnelList.find(
              (r) =>
                r.firstname == this.foreman.split(" ")[0] &&
                r.lastname == this.foreman.split(" ")[1]
            ).id
          : this.foreman,
        type: this.type,
        senTech: isNaN(this.senTech)
          ? this.senTechList.find(
              (r) =>
                r.firstname == this.senTech.split(" ")[0] &&
                r.lastname == this.senTech.split(" ")[1]
            ).id
          : this.senTech,
      },
      techsToAdd,
    ];
    console.log(shiftItem);
    if (this.row.id != undefined) {
      shiftItem[0].id = this.row.id;
      shifts
        .editShift(
          this.$store.getters.getToken,
          shiftItem,
          this.$store.getters.getHost
        )
        .then((r) => {
          if (r.status == 400) {
            this.$message.error(r.error);
          } else {
            this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "ShiftList");
            this.$message.success("Shift Edited");
            this.$router.replace({
              name: "load",
            });
          }
        });
    } else {
      shifts
        .addShift(
          this.$store.getters.getToken,
          shiftItem,
          this.$store.getters.getHost
        )
        .then((r) => {
          if (r.status == 400) {
            this.$message.error(r.error);
          } else {
            this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "ShiftList");
            this.$message.success({
              message: "Shift Added",
              showClose: true, 
            });;
            this.$router.replace({
              name: "load",
            });
          }
        });
    }
  }
  public pushStandbyPersonnel() {
    this.standbyTechs.push({
      id:
        this.personnelList.find((r) => r.id == this.selectedStandbyTech)
          .firstname +
        " " +
        this.personnelList.find((r) => r.id == this.selectedStandbyTech)
          .lastname,
      value: this.selectedStandbyTech,
      start: "08:00",
      end: "16:00",
    });
    this.selectedStandbyTech = "";
    this.availableForemen = [];
    this.availablePersonnel = [];
    // console.log(this.standbyTechs);
  }
  public standbyTechUpdate(id: any, index: number) {
    if (id == "") {
      this.standbyTechs.splice(index, 1);
    }
    // console.log(this.standbyTechs);
  }
  public endTimeStandbyChange(index: number) {
    if (this.standbyTechs[index].end.length === 2) {
      this.standbyTechs[index].end = this.standbyTechs[index].end + ":";
    }
  }
  public startTimeStandbyChange(index: number) {
    if (this.standbyTechs[index].start.length === 2) {
      this.standbyTechs[index].start = this.standbyTechs[index].start + ":";
    }
  }
}
</script>
<style scoped>
.white {
  color: white;
}
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 140px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
.standbyTag {
  background-color: rgb(230, 230, 230);
  color: black;
  width: 140px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}

hr {
  border: none;
  height: 2px;
  background-color: #404740;
  margin: 20px 0; 
  opacity: 0.7; 
}

</style>
