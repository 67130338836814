<template>
  <div>
    <el-row class="container">
      <ShiftFilters v-model="filters"></ShiftFilters>
      <br />
      <el-input
        v-model="searchQuery"
        placeholder="Search Manually"
        style="margin-bottom: 10px;"
      ></el-input>
      <el-button @click="search" type="success">
        <strong class="white">Search</strong>
      </el-button>
    </el-row>
    <br />
    <el-row class="container">
      <el-table
        :data="filteredShiftList"
        v-loading="loading"
        border
        :header-cell-style="{
          background: 'rgb(170, 238, 238)',
          color: 'black',
          'text-align': 'center',
        }"
        :row-key="getRowKey"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="personnel">
              <p>
                <strong>Shift Personnel:</strong>
              </p>
            </div>
            <table style="font-weight:normal;">
              <tr>
                <th class="personnelHead">
                  <strong>Last Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>First Name</strong>
                </th>
                <th class="personnelHead">
                  <strong>Time Started</strong>
                </th>
                <th class="personnelHead">
                  <strong>Time Ended</strong>
                </th>
                <th class="personnelHead">
                  <strong>Type</strong>
                </th>
              </tr>
              <tr>
                <th class="personnel">{{ props.row.foreman.lastname }}</th>
                <th class="personnel">{{ props.row.foreman.firstname }}</th>
                <th class="personnel">
                  {{
                    typeList.find((obj) => obj.id === props.row.type).time_start
                  }}
                </th>
                <th class="personnel">
                  {{
                    typeList.find((obj) => obj.id === props.row.type).time_end
                  }}
                </th>
                <th class="personnel">Shift</th>
              </tr>
              <tr v-for="p in props.row.personnel" :key="p.personnel_id + '-shift'">
                <th class="personnel" v-if="!p.standby">
                  {{
                    personnelList.find((obj) => obj.id == p.personnel_id)
                      .lastname
                  }}
                </th>
                <th class="personnel" v-if="!p.standby">
                  {{
                    personnelList.find((obj) => obj.id == p.personnel_id)
                      .firstname
                  }}
                </th>
                <th class="personnel" v-if="!p.standby">
                  {{ getDateTime(p.date_started) }}
                </th>
                <th class="personnel" v-if="!p.standby">
                  {{ getDateTime(p.date_ended) }}
                </th>
                <th class="personnel" v-if="!p.standby">
                  {{ p.standby ? "Standby" : "Shift" }}
                </th>
              </tr>
              <tr v-for="p in props.row.personnel" :key="p.personnel_id + '-standby'">
                <th class="personnel" v-if="p.standby">
                  {{
                    personnelList.find((obj) => obj.id == p.personnel_id)
                      .lastname
                  }}
                </th>
                <th class="personnel" v-if="p.standby">
                  {{
                    personnelList.find((obj) => obj.id == p.personnel_id)
                      .firstname
                  }}
                </th>
                <th class="personnel" v-if="p.standby">
                  {{ getDateTime(p.date_started) }}
                </th>
                <th class="personnel" v-if="p.standby">
                  {{ getDateTime(p.date_ended) }}
                </th>
                <th class="personnel" v-if="p.standby">
                  {{ p.standby ? "Standby" : "Regular" }}
                </th>
              </tr>
              <tr v-if="props.row.senTech != undefined">
                <th class="personnel">{{ props.row.senTech.lastname }}</th>
                <th class="personnel">{{ props.row.senTech.firstname }}</th>
                <th class="personnel">
                  {{
                    typeList.find((obj) => obj.id === props.row.type).time_start
                  }}
                </th>
                <th class="personnel">
                  {{
                    typeList.find((obj) => obj.id === props.row.type).time_end
                  }}
                </th>
                <th class="personnel">AIA Sen. Tech</th>
              </tr>
            </table>
          </template>
        </el-table-column>
        <el-table-column
          prop="shift_date"
          label="Shift Date"
          sortable
          :formatter="filterDateTime"
        ></el-table-column>
        <el-table-column
          prop="type"
          align="center"
          label="Type"
          width="140"
          :filters="getTypeFilters()"
          :filter-method="filterHandler"
          :formatter="getType"
        ></el-table-column>
        <el-table-column
          prop="foreman"
          label="Foreman"
          width="250px"
          :filters="foremen"
          :formatter="getForeman"
          :filter-method="foremanFilter"
        ></el-table-column>
        <el-table-column fixed="right" label="Actions" width="120">
          <template slot-scope="scope">
            <el-button
              circle
              type="primary"
              icon="el-icon-edit"
              @click="handleClick(scope.$index, scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteShift(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <br />
  </div>
</template>

<script lang="ts">
import ShiftFilters from "../Filters/ShiftFilters.vue";
import { shifts } from "../../api/shifts/shifts";
import { personnel } from "../../api/personnel/personnel";
import { Component, Vue } from "vue-property-decorator";
import Moment from "moment";

@Component({
  name: "ShiftList",
  components: {
    ShiftFilters,
  },
})
export default class ShiftList extends Vue {
  public loading: boolean = false;
  public filters: any = {};
  public shiftList: any[] = [];
  public typeList: any[] = [];
  public personnelList: any[] = [];
  public foremen: any[] = [];
  public tableData: any[] = [];
  public index: number = 0;
  public listEnd: boolean = false;
  public searchQuery: string = "";
  props: any;
  scope: any;

  public handleClick(rowIndex: number, row: any) {
    console.log("ROW");
    console.log(row);
    row.personnel = JSON.stringify(row.personnel);
    this.$store.dispatch("updateCurrentObject", row);
    this.$store.dispatch("updateCurrentComponent", "AddShift");
    this.$router.replace({ name: "load" });
  }

  public getDateTime(date: any) {
    if (date != undefined && date != "") {
      return Moment(date).format("HH:mm");
    }
    return "";
  }
  public more() {
    let i = 0;
    while (i < 10) {
      this.tableData.push(this.shiftList[this.index + 1]);
      i++;
      this.index++;
      if (this.shiftList[this.index + 1] == undefined) {
        this.index--;
        this.listEnd = true;
        return;
      }
    }
  }
  public async deleteShift(rowIndex: number, row: any) {
    await shifts
      .recycleShift(
        this.$store.getters.getToken,
        row.id,
        this.$store.getters.getHost
      )
      .then(async (r) => this.search());
  }

  public parseForemen() {
    console.log(this.shiftList);
    for (const s of this.shiftList) {
      console.log("666-1");
      try {
        if (
          !this.foremen.some(
            (e) => e.text === s.foreman.lastname + " " + s.foreman.firstname
          )
        ) {
          this.foremen.push({
            text: s.foreman.lastname + " " + s.foreman.firstname,
            value: s.foreman.lastname + " " + s.foreman.firstname,
          });
        }
      } catch (ex) {
        console.log(ex + "--- 666-3-" + JSON.stringify(s));
      }
    }
    console.log("666-2 " + JSON.stringify(this.foremen));
  }

  public filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }

  public foremanFilter(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property].lastname + " " + row[property].firstname === value;
  }

  public getType(row: any, column: any): String {
    let type = this.typeList.find((obj: any) => obj.id === row.type);
    if (type != undefined) {
      return type.literal;
    }
    return "";
  }

  public getForeman(row: any, column: any): String {
    return row.foreman.lastname + " " + row.foreman.firstname;
  }

  public getTypeFilters() {
    let a: any = [];
    {
      for (const s of this.typeList) {
        a.push({ value: s.id, text: s.literal });
      }
    }
    return a;
  }

  public filterDateTime(row: any) {
    if (row.shift_date === undefined) {
      return "";
    }
    return Moment(row.shift_date).format("DD/MM/YYYY");
  }

  public async getShiftTypeList() {
    await shifts
      .getShiftTypes(this.$store.getters.getToken, this.$store.getters.getHost)
      .then((response) => {
        response.json().then((resp: any) => {
          this.typeList = JSON.parse(JSON.stringify(resp));
        });
      });
  }

  public async getPersonnelList() {
    await personnel
      .getAllPersonnel(
        this.$store.getters.getToken,
        this.$store.getters.getHost
      )
      .then((response) => {
        response.json().then(async (resp) => {
          this.personnelList = JSON.parse(JSON.stringify(resp));
          console.log(JSON.stringify(this.personnelList));
        });
      });
  }

  public async search() {
    this.listEnd = false;
    this.loading = true;
    this.listEnd = false;
    if (this.filters.dateFrom === null) {
      delete this.filters.dateFrom;
    }
    if (this.filters.dateTo === null) {
      delete this.filters.dateTo;
    }
    let shiftlist = await (
      await shifts.getSomeShifts(
        this.$store.getters.getToken,
        this.filters,
        this.$store.getters.getHost
      )
    ).json();
    console.log(JSON.stringify(shiftlist));
    this.shiftList = JSON.parse(JSON.stringify(shiftlist));
    this.shiftList = this.shiftList.filter(shift => !shift.recycled); // Exclude recycled shifts
    this.shiftList.sort(function (a, b) {
      var aType = a.type;
      var bType = b.type;
      var aDate = new Date(
        Moment(a.shift_date).hour(0).minutes(0).toDate()
      ).getTime();
      var bDate = new Date(
        Moment(b.shift_date).hour(0).minutes(0).toDate()
      ).getTime();

      if (aDate == bDate) {
        return aType > bType ? -1 : aType < bType ? 1 : 0;
      } else {
        return aDate > bDate ? -1 : 1;
      }
    });

    this.tableData = this.shiftList.slice(0, 10);
    this.index = 10;
    this.parseForemen();
    this.loading = false;
  }

  public async created() {
    this.loading = true;
    await this.getPersonnelList();
    await this.getShiftTypeList();
    this.loading = false;
  }

  get filteredShiftList() {
    if (!this.searchQuery) {
      return this.shiftList;
    }
    return this.shiftList.filter((shift) => {
      let foremanName =
        shift.foreman.lastname + " " + shift.foreman.firstname;
      let typeName = this.getType(shift, null);
      let shiftDate = Moment(shift.shift_date).format("DD/MM/YYYY");
      return (
        foremanName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        typeName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        shiftDate.includes(this.searchQuery)
      );
    }).filter(shift => !shift.recycled); // Exclude recycled shifts
  }

  public getRowKey(row: any) {
    return row.id;
  }
}
</script>

<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
.personnel {
  background-color: rgb(230, 242, 255);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.personnelHead {
  background-color: rgb(255, 242, 240);
  font-weight: normal !important;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
</style>
