<template>
  <div>
    <h3>Add Internal Information</h3>
    <br />
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Subject:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input
          size="mini"
          v-model="subject"
          placeholder="Subject"
        ></el-input>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Content:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input
          size="mini"
          v-model="content"
          placeholder="Content"
          type="textarea"
          :rows="10"
        ></el-input>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-button style="margin-top: 12px;" @click="submit" type="success">
        <strong class="white">Submit</strong>
      </el-button>
    </el-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { internalInformation } from "../../api/internalinformation/internalinformation";
@Component({
  name: "AddInternalInformation",
  components: {},
  props: {
    isDialog: {
      default: false,
    },
  },
  computed: {},
})
export default class AddInternalInformation extends Vue {
  @Emit("added")
  test() {
    console.log("added called");
  }
  public subject: string = "";
  public content: string = "";
  public row: any = {};
  public created() {
    this.checkEdit();
  }

  public mounted() {
    console.log(this.$props.isDialog);
  }
  
  public submit() {
    if (this.row.id == undefined) {
      internalInformation
        .sendMessage(
          this.$store.getters.getToken,
          { subject: this.subject, content: this.content },
          this.$store.getters.getHost
        )
        .then((r) => {
          if (r.status == 400) {
            this.$message.error(r.error);
          } else {
            if (this.$props.isDialog === false) {
              this.$store.dispatch("updateCurrentObject", {});
              this.$store.dispatch(
                "updateCurrentComponent",
                "InternalInformationList"
              );

              this.$router.replace({
                name: "load",
              });
            }
            this.$message.success("Internal Information Edited");
            this.test();
          }
        });
    } else {
      internalInformation
        .editMessage(
          this.$store.getters.getToken,
          { subject: this.subject, content: this.content, id: this.row.id },
          this.$store.getters.getHost
        )
        .then((r) => {
          if (r.status == 400) {
            this.$message.error(r.error);
          } else {
            if (this.$props.isDialog === false) {
              this.$store.dispatch("updateCurrentObject", {});
              this.$store.dispatch(
                "updateCurrentComponent",
                "InternalInformationList"
              );

              this.$router.replace({
                name: "load",
              });
            }
            this.$message.success("Internal Information Edited");
            this.test();
          }
        });
    }
  }
  public beforeDestroy() {
    this.$store.dispatch("updateCurrentObject", {});
  }
  public checkEdit() {
    this.row = this.$store.getters.getCurrentObject;
    if (this.row.id != undefined) {
      this.subject = this.row.subject;
      this.content = this.row.content;
    }
  }
}
</script>
<style>
.white {
  color: white;
}
</style>
