<template>
  <div class="container">
    <h1>Vacation</h1>
    <div class="title_section">
      <hr />
      <h2>Employee Details</h2>
      <hr />
    </div>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Entitled Year:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-input
          v-model="year_vacation"
          placeholder="Year"
          size="mini"
          :style="{ width: '230px' }"
        ></el-input>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Department:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="department"
          filterable
          :style="{ width: '230px' }"
          remote
          size="mini"
          clearable
          placeholder="Department"
          value-key="id"
          :remote-method="departmentMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in availableDepartments"
            :key="item.id"
            :label="item.literal"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Name:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-select
          v-model="name"
          filterable
          :style="{ width: '230px' }"
          remote
          size="mini"
          clearable
          placeholder="Name"
          value-key="id"
          :remote-method="nameMethod"
          :loading="loading"
          @change="getDaysReceived()"
        >
          <!-- :label="item.firstname+' '+item.lastname+(!item.active?' (INACTIVE) ':'')" -->
          <el-option
            v-for="item in availableName"
            :key="item.id"
            :label="
              item.firstname +
                ' ' +
                item.lastname +
                (!item.active ? ' (INACTIVE) ' : '')
            "
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <div class="title_section">
      <hr />
      <h2>Request for Days</h2>
      <hr />
    </div>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Request for :</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-input
          v-model="total_days_req"
          size="mini"
          placeholder="Days Requested"
          :style="{ width: '230px' }"
        ></el-input>
      </el-col>
    </el-row>
    <br />

    <!-- <el-row v-for="(index, k) in leave_on_end" :key="k">
      
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave on:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
          @change="vacationUpdate(k)"
          v-model="index.leave_on"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{ width: '230px' }"
        ></el-date-picker>
      </el-col>

      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave end:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
          @change="vacationUpdate(k)"
          v-model="index.leave_end"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{ width: '230px' }"
        ></el-date-picker>
      </el-col>
    </el-row> -->

    <!-- new -->
    <el-row>
      <!-- {{index}} -->
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave on:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
          @change="pushVacation"
          v-model="leaveOn"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{ width: '230px' }"
        ></el-date-picker>
      </el-col>

      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Leave end:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-date-picker
          @change="pushVacation"
          v-model="leaveEnd"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
          :style="{ width: '230px' }"
        ></el-date-picker>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Type:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          value-key="id"
          :style="{ width: '230px' }"
          v-model="type"
          placeholder="Type"
          size="mini"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.type_of_vacation"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <div class="title_section">
      <hr />
      <h2>Request for Hours</h2>
      <hr />
    </div>
    <br />
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>From:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-time-select
          v-model="from_time"
          size="mini"
          placeholder="Select time"
          :style="{ width: '230px' }"
          :picker-options="{
            start: '00:00',
            step: '01:00',
            end: '23:00',
          }"
          format="HH:mm"
          value-format="HH:mm"
        ></el-time-select>
      </el-col>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>To:</strong>
        </div>
      </el-col>
      <el-col :span="6" align="left">
        <el-time-select
          v-model="to_time"
          size="mini"
          placeholder="Select time"
          :style="{ width: '230px' }"
          :picker-options="{
            start: '00:00',
            step: '01:00',
            end: '23:00',
            minTime: from_time,
          }"
          @change="setHoursRequested()"
          format="HH:mm"
          value-format="HH:mm"
        ></el-time-select>
      </el-col>
    </el-row>
    <br />
    <el-row v-if="hoursRequested">
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Hours Requested:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <div class="hours-requested-value" align="center">
          {{ hoursRequested }}
        </div>
      </el-col>
    </el-row>
    <div class="title_section">
      <hr />
      <h2>Note</h2>
      <hr />
    </div>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Date of Request:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-date-picker
          :style="{ width: '230px' }"
          @change="fixDateofRequest"
          v-model="DateOfRequest"
          type="date"
          placeholder="Date"
          format="dd/MM/yyyy"
          size="mini"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" align="senter">
        <el-button
          @click="addToDBVacationDecision"
          style="margin-top: 12px;"
          type="success"
        >
          <strong class="white">Submit</strong>
        </el-button>
      </el-col>
      <el-col :span="12" align="senter">
        <!--exportVA(id)   id einai to row pou exei epilexthei-->
        <el-button
          @click="exportVA(id)"
          style="margin-top: 12px;"
          type="success"
        >
          <strong class="white">Export form</strong>
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Emit from "vue-property-decorator";
import { StringHelpers } from "../../api/helpers/StringHelpers";
import { status } from "../../api/status/status";
import { workorder } from "../../api/workorder/workorder";
import { personnel } from "../../api/personnel/personnel";
import { vacation } from "../../api/vacation";
import { postgrest } from "../../api/postgrest";

import Moment from "moment";

import { DateTime } from "luxon";
import { Console } from "console";

@Component({
  name: "AddNewVacation",
})
export default class AddNewVacation extends Vue {
  public id: any = "";
  public name: any = "";
  public total: any[] = [];
  public leaveOn: any = "";
  public ReturnOn: any = "";
  public leaveEnd: any = "";
  public json_leaveon_leaveend: any = "";
  public year_vacation: any = "";
  public type: any = "";
  public types: any[] = [];
  public hoursRequested: any = "";

  public DateOfRequest: any = "";
  public availableName: any[] = [];
  public availableDepartments: any[] = [];
  public availableType: any[] = [];
  public personnelList: any[] = [];
  public typeList: any[] = [];
  public loading: boolean = false;
  public total_days_req: any = "";
  public hours_req: any = "";
  public row: any = "";
  public leave_on_end: any[] = [];
  public department: any = "";
  public from_time: any = "";
  public to_time: any = "";
  public vacationList: any = "";
  public total_days_received: any;

  // public leaveon: any = "";

  public pushVacation() {
    if (this.leaveOn != "" && this.leaveEnd != "") {
      let x = Moment(this.leaveOn)
        .add(5, "hours")
        .format("YYYY-MM-DD");
      let y = Moment(this.leaveEnd)
        .add(10, "hours")
        .format("YYYY-MM-DD");

      console.log("x is:" + x);
      console.log("y is:" + y);

      this.leave_on_end.push({ leave_on: x, leave_end: y });
      console.log("this.leave_on_end--> " + JSON.stringify(this.leave_on_end));
      // this.leaveOn = "";
      // this.leaveEnd = "";
    } else {
      console.log("end");
      return;
    }
  }

  public getDaysReceived() {
    console.log("it works");
    console.log(JSON.stringify(this.vacationList));
    console.log(this.name.id);

    for (let i = 0; i < this.vacationList.length; i++) {
      let vacation = this.vacationList[i];
      if (vacation.idpersonel === this.name.id) {
        console.log("Total days received:", vacation.total_days_received);
        this.total_days_received = vacation.total_days_received;
        return this.total_days_received;
      }
    }
  }

  public vacationUpdate(k: number) {
    if (
      this.leave_on_end[k].leave_on == null &&
      this.leave_on_end[k].leave_end == null
    ) {
      this.leave_on_end.splice(k, 1);
    }
    console.log("this.leave_on_end -> " + this.leave_on_end);
  }
  public setHoursRequested() {
    let fromMoment = Moment(this.from_time, "HH:mm");
    let toMoment = Moment(this.to_time, "HH:mm");
    // Calculate the difference in hours
    this.hoursRequested = toMoment.diff(fromMoment, "hours");
    console.log("Hours requested: " + this.hoursRequested);
  }

  public fixReturnOn(a) {
    console.log("-----");
    console.log(a);
    this.ReturnOn = Moment(a)
      .add(5, "hours")
      .format("YYYY-MM-DD");
    console.log(this.ReturnOn);
  }

  public fixDateofRequest(a) {
    console.log("=====");
    console.log(a);
    this.DateOfRequest = Moment(a)
      .add(5, "hours")
      .format("YYYY-MM-DD");
    console.log(this.DateOfRequest);
  }

  public addToDBVacationDecision() {
    console.log("1");
    this.ReturnOn = null;
    console.log("+++ " + this.ReturnOn);
    if (this.row.id == undefined) {
      this.addToDBVacation();
    } else {
      this.addToDBVacation2();
    }
  }

  public addToDBVacation() {
    console.log("entered addToDBVacation");
    //dimiourgia item. aristera idia met me api front, deksia idia met m v-model tou idiou
    if (this.leaveOn != "" && this.leaveEnd != "") {
      console.log("entered here")
      let a = Moment(this.leave_on_end[0].leave_on).format("YYYY/MM/DD");
      a = a.replace("/", "-");
      a = a.replace("/", "-");
      console.log("replaced a-> " + a);
      let b = Moment(this.leave_on_end[0].leave_end).format("YYYY/MM/DD");
      b = b.replace("/", "-");
      b = b.replace("/", "-");
      console.log("replaced b-> " + b);
      console.log("Hours requested " + this.hoursRequested);

      if (a <= b) {
        console.log("bhka edw");
        console.log(this.ReturnOn);

        let hoursRequested =
          this.hoursRequested === "" ? null : this.hoursRequested;
        let fromTime = this.from_time === "" ? null : this.from_time;
        let toTime = this.to_time === "" ? null : this.to_time;

        let vacationItem: any[] = [
          {
            idper: this.name.id,
            total_days_req: this.total_days_req,
            leaveOn: this.leave_on_end[0].leave_on,
            type: this.type.id,
            DateOfRequest: this.DateOfRequest,
            leaveEnd: this.leave_on_end[0].leave_end,
            json_leaveon_leaveend: JSON.stringify(this.leave_on_end),
            year_vacation: this.year_vacation,
            department: this.department,
            hours_req: hoursRequested,
            from_time: fromTime,
            to_time: toTime,
            return_on: this.ReturnOn

            // total_days_received: this.total_days_received
          },
        ];

        console.log("vacationItem --> " + JSON.stringify(vacationItem));

        //api front addVacation
        vacation
          .addVacation(
            this.$store.getters.getToken,
            vacationItem,
            this.$store.getters.getHost
          )
          .then((r) => {
            if (r.status == 400) {
              console.log("EERROORRR");
              this.$message.error(r.error);
            } else {
              console.log("+++++++++");
              /* this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "ShiftList"); */
              this.$message.success({
              message: "Vacation Added",
              showClose: true, 
            });
              this.$router.replace({
                name: "load",
              });
              //this.$store.dispatch("updateCurrentObject", {});
            }
          });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Incorrect dates",
        });
      }
    }
    else if(this.leaveOn == "" && this.leaveEnd == ""){
      console.log("no values for leaveOn and leaveEnd")

      console.log(this.leaveOn);

        let hoursRequested =
          this.hoursRequested === "" ? null : this.hoursRequested;
        let fromTime = this.from_time === "" ? null : this.from_time;
        let toTime = this.to_time === "" ? null : this.to_time;

        let leaveOn = this.leaveOn === "" ? null : this.leaveOn;
        let leaveEnd = this.leaveOn === "" ? null : this.leaveOn;
        let total_days_req = this.total_days_req === "" ? null : this.total_days_req;
        let type = this.type === "" ? null : this.type;


        let vacationItem: any[] = [
          {
            idper: this.name.id,
            total_days_req: total_days_req,
            leaveOn: leaveOn,
            type: type,
            DateOfRequest: this.DateOfRequest,
            leaveEnd: leaveEnd,
            json_leaveon_leaveend: '',
            year_vacation: this.year_vacation,
            department: this.department,
            hours_req: hoursRequested,
            from_time: fromTime,
            to_time: toTime,
            // total_days_received: this.total_days_received
          },
        ];

        console.log("vacationItem --> " + JSON.stringify(vacationItem));

        //api front addVacation
        vacation
          .addVacation(
            this.$store.getters.getToken,
            vacationItem,
            this.$store.getters.getHost
          )
          .then((r) => {
            if (r.status == 400) {
              console.log("EERROORRR");
              this.$message.error(r.error);
            } else {
              console.log("+++++++++");
              /* this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "ShiftList"); */
              this.$message.success({
              message: "Vacation Added",
              showClose: true, 
            });;
              this.$router.replace({
                name: "load",
              });
              //this.$store.dispatch("updateCurrentObject", {});
            }
          });

    }
  }
  public addToDBVacation2() {
    let vacationItem: any[] = [
      {
        id: this.row.id,
        idper: this.name.id,
        total_days_req: this.total_days_req,
        hours_req: this.hours_req,
        leaveOn: this.leave_on_end[0].leave_on,
        ReturnOn: this.ReturnOn,
        type: this.type.id,

        DateOfRequest: this.DateOfRequest,
        leaveEnd: this.leave_on_end[0].leave_end,
        json_leaveon_leaveend: JSON.stringify(this.leave_on_end),
        year_vacation: this.year_vacation,
        department: this.department,
      },
    ];

    //api front addVacation
    //console.log("Frontend token:" + this.$store.getters.getToken);
    vacation
      .addVacation2update(
        this.$store.getters.getToken,
        vacationItem,
        this.$store.getters.getHost
      )
      .then((r) => {
        //console.log("r =====> " + JSON.stringify(r));
        if (r.status == 400) {
          this.$message.error(r.error);
        } else {
          /* this.$store.dispatch("updateCurrentObject", {});
            this.$store.dispatch("updateCurrentComponent", "ShiftList"); */
          this.$message.success({
              message: "Vacation Added",
              showClose: true, 
            });;
          this.$router.replace({
            name: "load",
          });
        }
      });
  }
  //apla gemizi tin forma tou add
  //gia to edit
  public async checkEdit() {
    this.row = this.$store.getters.getCurrentObject;
    console.log(JSON.stringify(this.row));
    console.log(JSON.stringify("this.rowvacation"));
    if (this.row.id != undefined) {
      let met = this.row.idpersonel;
      let xxx: any = {
        key: this.row.idpersonel,
        label:
          this.personnelList.find(function(element) {
            return met == element.id;
          }).firstname +
          " " +
          this.personnelList.find(function(element) {
            return met == element.id;
          }).lastname,
        value: this.row.idpersonel,
        id: this.row.idpersonel,
      };
      this.name = {};
      Vue.set(this.name, "key", xxx.key);
      Vue.set(this.name, "value", xxx.value);
      Vue.set(this.name, "label", xxx.label);
      Vue.set(this.name, "id", xxx.id);

      this.year_vacation = this.row.year_vacation;
      this.total_days_req = this.row.total_days_req;
      this.hours_req = this.row.hours_req;

      let x = JSON.parse(this.row.json_leaveon_leaveend);
      this.leave_on_end = x;

      this.ReturnOn = new Date(this.row.return_on);

      this.DateOfRequest = new Date(this.row.date_of_request);

      let met2 = this.row.type_of_vacation;
      let yyy: any = null;
      //console.log("ROOOOOW:" + JSON.stringify(this.row));
      try {
        //console.log("typeList ====> " + JSON.stringify(this.typeList));
        let result: any = this.typeList.find(function(element) {
          return met2 == element.id;
        });
        //console.log("result => " + JSON.stringify(result));
        result = result.type_of_vacation;
        yyy = {
          key: this.row.type_of_vacation,
          label: result,
          value: this.row.type_of_vacation,
          id: this.row.type_of_vacation,
        };
      } catch (ex) {
        // console.log("Exception:" + ex);
      }

      this.type = {};
      Vue.set(this.type, "key", yyy.key);
      Vue.set(this.type, "label", yyy.label);
      Vue.set(this.type, "value", yyy.value);
      Vue.set(this.type, "id", yyy.id);
    }
    this.$store.dispatch("updateCurrentObject", {});
  }
  //rows atofia
  public nameMethod(query: string) {
    console.log("++++")
    this.loading = false;
    this.availableName = this.personnelList.filter((item) => {
      return (
        (item.firstname.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          item.lastname.toLowerCase().indexOf(query.toLowerCase()) > -1) &&
        (item.active || this.$store.getters.getUserLevel < 1)
      );
    });
  }

  public departmentMethod(query: string) {
    this.loading = false;
    console.log("+++++ " + JSON.stringify(this.availableDepartments));
  }

  public async getPersonnelList() {
    this.personnelList = await postgrest.get(
      "personnel?select=*,companies(*),wo_specialties(*),specialties(*),departments(*)",
      this.$store.getters.getToken
    );

    console.log("Response:", this.personnelList);
  }

  public async getVacationList() {
    this.vacationList = await postgrest.get(
      "vacation_summary_view?select=*",
      this.$store.getters.getToken
    );
    console.log("eeeee " + JSON.stringify(this.vacationList));
  }

  public async getDepartments() {
    let token = this.$store.getters.getToken;
    console.log("Token:", token);

    let response = await postgrest.get("departments?select=*", token);
    console.log("Response:", response);

    this.availableDepartments = response.map(
      (department: { literal: any }) => department.literal
    );
  }

  public exportVA(id: any) {
    this.$store.dispatch("setCurrentId", id);
    this.$store.dispatch("updateCurrentComponent", "DownloadVacation");
    this.$router.replace({
      name: "load",
    });
  }

  public async getTypeList() {
    //to arxio tou api einai to vacation
    let resp = await postgrest.get(
      "type_vacation",
      this.$store.getters.getToken
    );
    this.typeList = JSON.parse(JSON.stringify(resp));
  }
  //energop automata
  public async created() {
    postgrest.SetHost(this.$store.getters.getConnectionSettings.postgrest);
    await this.getPersonnelList();
    await this.getTypeList();
    await this.getDepartments();
    await this.getVacationList();
    this.nameMethod("");
    this.checkEdit();
  }
}
</script>

<style scoped>
strong {
  color: black;
}

.title_section {
  text-align: left;
  margin: 20px 0;
}

.title_section h2 {
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 140px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}

.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}

.hours-requested-value {
  font-size: 14pt;
  font-weight: bold;
  color: #333;
  padding: 5px 10px;
  background-color: #e9ecef;
  border-radius: 4px;
  width: 10%;
}
</style>
