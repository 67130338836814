<template>
  <div>
    <h2>Status</h2>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Status:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-select
          v-model="details.selectedStatus"
          :style="{width:'200px'}"
          placeholder="Status"
          :items="statuses"
          size="mini"
        >
          <el-option
            v-for="item in statuses"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6" align="left">
        <div class="tag">
          <strong>Completion Date:</strong>
        </div>
      </el-col>
      <el-col :span="18" align="left">
        <el-date-picker
          v-if="details.selectedStatus!=1"
          v-model="details.enddate"
          :style="{width:'200px'}"
          type="date"
          size="mini"
          placeholder="Completion date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
         :formatter="Completiondate"
        ></el-date-picker>
        <!-- value-format="dd/MM/yyyy" -->
      <!-- :formatter="Completiondate" ?? -->
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { status } from "../../api/status/status";

import Moment from "moment";

@Component({
  name: "StatusSelector",
  props: { details: { enddate: "", selectedStatus: "" } as any },
  model: { prop: "details", event: "change" }
})
export default class StatusSelector extends Vue {
  public statuses: any[] = [];
  details: any;

  public async getStatusList() {
    await status
      .getAllStatuses(this.$store.getters.getToken, this.$store.getters.getHost)
      .then(response => {
        response.json().then(resp => {
          let i = 0;
          while (i <= JSON.parse(JSON.stringify(resp)).length - 1) {
            this.statuses.push({
              value: JSON.parse(JSON.stringify(resp))[i].id,
              label: JSON.parse(JSON.stringify(resp))[i].literal
            });
            i++;
          }
        });
      });
  }
public Completiondate(row: any) {
    if (row.status.enddate === undefined) {
      return "";
    }
    console.log("123456")
    return Moment(row.status.enddate).format("DD/MM/YYYY HH:mm");

  return "x"
  }


  public async mounted() {
    await this.getStatusList();
  }
}
</script>
<style scoped>
.tag {
  background-color: rgb(201, 228, 238);
  color: black;
  width: 130px;
  border: 1px solid rgb(53, 167, 167);
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  font-size: 9pt;
  line-height: 20px;
}
strong {
  color: black;
}
</style>